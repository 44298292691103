import React from 'react';
import ProductUnitForm from '../components/ProductUnitForm'
import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Modal
  ,Card,Form,Select,InputNumber,Drawer,Descriptions,Popconfirm,message } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,
  EditOutlined,
  ShoppingCartOutlined,
  AppstoreAddOutlined,
  DeleteOutlined,
  FundViewOutlined,
  PlusOutlined,
  
} from '@ant-design/icons';
import QRCode from 'react-qr-code';
import { Link } from 'react-router-dom';
import * as serverconfig from '../../serverconn'
import CurrencyFormat from 'react-currency-format';
import {
  Col,
  Row,
} from 'reactstrap';
import PhoneInput from 'react-phone-number-input'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

const FormItem=Form.Item;
const { Option } = Select;
const { TextArea } = Input;


function callback(key) {
  console.log(key);
}

class LicencingList extends React.Component {
  formRef = React.createRef();

  state = {
    allinstances: [],
    searchText: '',
    searchedColumn: '',
  };

  componentDidMount(){
    let form_data = new FormData();
    axios.post(serverconfig.backendserverurl+`/customqueries/getall_instance_licences`,form_data,{
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
    .then(res => {
        this.setState({
          allinstances:JSON.parse(res.data.allinstances)   
        })
    })
}



getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};



  render() {
 
    const columns = [
      {
        title: 'Client name',
        dataIndex: 'client_name',
        key: 'id',
        ...this.getColumnSearchProps('client_name'),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'id',
        ...this.getColumnSearchProps('status'),
        render: (text,record) =>
        <p
        style={{
          color:
            text==="active"?
            "green":
            text==="expired"?
            "red":"orange"
        }}
        >
          {text}
        </p>,
      },
      {
        title: 'Unique ID',
        dataIndex: 'unique_identifier',
        key: 'id',
        ...this.getColumnSearchProps('unique_identifier'),
      },
      {
        title: 'Instance/branch name',
        dataIndex: 'sacco_name',
        key: 'id',
        ...this.getColumnSearchProps('sacco_name'),
      },
      {
        title: 'Total licencing period',
        dataIndex: 'licence_totalperiod',
        key: 'id',
        ...this.getColumnSearchProps('licence_totalperiod'),
      },
      {
        title: 'Last licencing date',
        dataIndex: 'last_licencing_date',
        key: 'id',
        ...this.getColumnSearchProps('last_licencing_date'),
      },
      {
        title: 'Licencing period passed ',
        dataIndex: 'licence_period_passed',
        key: 'id',
      },

    ];

    return(
        <div>
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="INSTANCE LICENCING" key="1">
            <Table   
            columns={columns} 
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            dataSource={this.state.allinstances} 
            scroll={{ x: 1000 }}
            bordered/>
            </Panel>
            </Collapse>  

        </div>
    )
  }
}

export default LicencingList; 
