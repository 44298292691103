import React from 'react';
import axios from 'axios'
import { Table, Input, Button,Popconfirm,Popover,
  Collapse,Avatar,Drawer,Descriptions,message,Modal,Spin,Switch,Tabs} from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,FundOutlined,FundViewOutlined,DeleteOutlined,LockFilled,LockOutlined,LoadingOutlined } from '@ant-design/icons';
import { UserOutlined } from '@ant-design/icons';
import { Form,Select,Checkbox,Upload } from 'antd';
import { Link } from 'react-router-dom';
import * as serverconfig from '../../serverconn'
import InvoiceList from './InvoicesListView'
import ProductList from './ProductListView'
import UnitList from './ProductUnitListView'
import LicencingList from './LicencingListView'

var CryptoJS = require("crypto-js");
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { TabPane } = Tabs;

const { Panel } = Collapse;
const FormItem=Form.Item;

function callback(key) {
  console.log(key);
}

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});

var username=''

var token= ''

class Billings extends React.Component {
  state = {
   
    datarequested:true,
   
  };


  
  componentDidMount(){

    if(localStorage.getItem("username")){
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    }else{
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    this.setState({datarequested:false})

}


  render() {


    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin  size="large">
                <div className="content" />
              </Spin>       
        </div>
      )

    }else{

      return(
        <div>
          <Tabs defaultActiveKey="1" onChange={callback}>
          <TabPane tab="Client instance licences" key="00440030931" >
            <LicencingList />

          </TabPane>

          <TabPane tab="Product units" key="004430931" >
          <UnitList />
          </TabPane>

          <TabPane tab="Products" key="00443110931" >
            <ProductList />
          </TabPane>

          <TabPane tab="Invoices" key="003000931" >
            <InvoiceList />
          </TabPane>

          </Tabs>
        </div>
    )
    }
   
  }
}

export default Billings; 
