import React from 'react';
import { Form, Input, Button,Select,DatePicker,Spin,Image, Card,Divider,Result, message } from 'antd';
import axios from 'axios'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import moment from 'moment';
import * as serverconfig from '../../serverconn'
import {
  Col,
  Row,
} from 'reactstrap';
import { UserOutlined, LockOutlined, LoadingOutlined,MailOutlined } from '@ant-design/icons';
var CryptoJS = require("crypto-js");


const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;


var token= ''


class InstanceSMSSetupForm extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    message:'',
    sms_price:0,

  }


  componentDidMount(){

    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    this.setState({datarequested:false})
}


  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    let form_data = new FormData();

    var alldata={
      'sms_price':this.state.sms_price,
      'instance_id':this.props.instance_id,
      'instance':this.props.instance
    }  
    
    
    form_data.append('client_id', this.props.client_id);
    form_data.append('instance_id', this.props.instance_id);
    form_data.append('alldata', JSON.stringify(alldata));

    axios.post(serverconfig.backendserverurl+'/customqueries/instance_smsgatewaysetup', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
    .then(res =>{
      console.log(res.data)
      this.setState({datarequested:false})
      //window.location.reload(false)
      this.setState({message:res.data.message})
      this.setState({datasubmittedsuccessfully:true})
    }    
    )
    .catch(error => console.log(error))


  }


  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{


      if (this.state.datasubmittedsuccessfully===true){
        return(
            <Card>
            <Result
            status="success"
            title={this.state.message}
            extra={[
              <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
            ]}
            />
            </Card>
        );

      }else{

            return (
              <div>
                <h3 style={{color:'green'}}>SMS gateway setup</h3>

                <h5>SMS gatwaycode: {this.props.instance.smsgatewaycode}</h5>
                <h5>SMS balance: {this.props.instance.smsmessages}</h5>

                <Form onFinish={(event) => this.handleFormSubmit(event)}>


                <FormItem label="Client sms cost">
                  <Input  placeholder="Put cost here." value={this.state.sms_price} onChange={(val)=>{this.setState({sms_price:val.target.value})}} />
                </FormItem>

                <FormItem >
                  <Button  type='primary' htmlType="submit">Send request</Button>
                </FormItem>
      
                </Form>
              </div>
            );
          }
    }
    
  }

}

export default InstanceSMSSetupForm;



