import React from 'react';
import { Route } from 'react-router-dom';
import QuickMenu from './views/MainBranch/containers/QuickMenu'
import DashBoard from './views/MainBranch/containers/DashBoard'
import AccountList from './views/MainBranch/containers/AccountsListView'
import ClientsList from './views/MainBranch/containers/ClientsListView'
import SubdormainList from './views/MainBranch/containers/SubdormainsListView'
import Billings from './views/MainBranch/containers/billings'
import InvoiceForm from './views/MainBranch/containers/InvoiceFormView'

import ProductDetail from './views/MainBranch/containers/ProductDetailView'
import InvoiceEditForm from './views/MainBranch/containers/InvoiceFormEditView'
import InvoiceList from './views/MainBranch/containers/InvoicesListView'
import InvoicePaymentsList from './views/MainBranch/containers/InvoicePaymentsListView'


const SaccoBaseRouter = () =>(
     <div>
        <Route exact path='/' component={DashBoard} />
        <Route exact path='/quickmenu/' component={QuickMenu} />
        <Route exact path='/useraccounts/' component={AccountList} />
        <Route exact path='/clients/' component={ClientsList} />
        <Route exact path='/allsubdormains/' component={SubdormainList} />

        <Route exact path='/makequotation'component={DashBoard} />
        <Route exact path='/makeinvoice'component={InvoiceForm} />
        <Route exact path='/proformas'component={DashBoard} />
        <Route exact path='/proformas/:estimateID'component={DashBoard} />


        <Route exact path='/invoices'component={InvoiceList} />
        <Route exact path='/invoices/:invoiceID'component={InvoiceEditForm} />
        <Route exact path='/invoicepayments/:invoiceID'component={InvoicePaymentsList} />

        <Route exact path='/billings'component={Billings} />
        <Route exact path='/products/:productID' component={ProductDetail} />


    </div>
);

export default SaccoBaseRouter;


