import React from 'react';
import axios from 'axios';
import { Card, Button, Avatar,Table,Popover,Popconfirm,message,Modal,Switch } from 'antd';
import { Form, Input,Select } from 'antd';

import {
    Col,
    Row,
  } from 'reactstrap';

import { UserOutlined,FundViewOutlined,DeleteOutlined,EditOutlined } from '@ant-design/icons';
import QRCode from 'qrcode.react';
import * as serverconfig from '../../serverconn'
import { Link } from 'react-router-dom';

const FormItem=Form.Item;
const { Option } = Select;

class ProductDetail extends React.Component{
    canvasRef = React.createRef();

    state={
        product: {},
        serial:'',
        productName:'' ,  
        unit:'',
        unit_selling_price:0,
        unit_buying_price:0,

        productDescription:'',
        productSerial:'',
        productImage:null,   
        primaryunits:'',
        productunits:[],
        ingredients:[],
        productID:'',
        dependantproduct:false,
        selectedingredient:{},
        showingredienteditmodal:false,
        ingredientquantity:0,
        subproducts:[],
        stockunits:[],
        hassubproducts:false,

        warningstocklevel:0,
        alertstocklevel:0,
        service_product:false,


    }

    componentDidMount(){
        const productID=this.props.match.params.productID;
        this.setState({productID:productID})

        axios.get(`${serverconfig.backendserverurl}/api/products/${productID}`)
        .then(res => {  
            this.setState({
                product: res.data
            })

            this.setState({serial:res.data.product_serial})
            this.setState({productName:res.data.product_name})
            this.setState({unit:res.data.unit})
            this.setState({unit_buying_price:res.data.unit_buying_price})
            this.setState({unit_selling_price:res.data.unit_selling_price})
            this.setState({productDescription:res.data.description})
            this.setState({productSerial:res.data.product_serial})


        })

        axios.get(serverconfig.backendserverurl+`/api/productunits/`)
        .then(res => {
            this.setState({
              productunits:res.data
            })
        })

    }


//submit button pressed
handleFormSubmit=(event) =>{
    const productID=this.props.match.params.productID;

    let form_data = new FormData();

    form_data.append('product_name', this.state.productName);
    form_data.append('unit', this.state.unit);
    form_data.append('unit_selling_price', this.state.unit_selling_price);
    form_data.append('unit_buying_price', this.state.unit_buying_price);

    form_data.append('description', this.state.productDescription);
    form_data.append('product_serial', this.state.productSerial);
    form_data.append('primaryunits', this.state.primaryunits);

    axios.put(serverconfig.backendserverurl+`/api/products/${productID}/`, form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res => window.location.reload(false)
  )
  .catch(error => console.log(error))

  }

  handleProductNameChange= (e) => this.setState({ productName: e.target.value});
  handleunitChange= (e) => this.setState({ unit: e});
  handleunitpriceChange= (e) => this.setState({ unitprice: e.target.value});
  handleProductDescriptionChange= (e) => this.setState({ productDescription: e.target.value});
  handleProductSerialChange= (e) => this.setState({ productSerial: e.target.value});
  handleProductImageChange= (e) => this.setState({ productImage: e.target.files[0]});
  handleprimaryunitsChange= (e) => this.setState({ primaryunits: e.target.value});

  handlewarningstocklevelChange= (e) => this.setState({ warningstocklevel: e.target.value});
  handlealertstocklevelChange= (e) => this.setState({ alertstocklevel: e.target.value});

  handleunit_selling_priceChange= (e) => this.setState({ unit_selling_price: e.target.value});

  
    render(){
       
        return(
              <div>
                <Row>

              <Col xs="12" sm="6" lg="6">
                <Card title="Product Update">

                <Form
                        onFinish={(event) => this.handleFormSubmit(event)}
                >
                    <FormItem label="Product Name">
                    <Input name="productname"  placeholder="Put a name here." value={this.state.productName} onChange={this.handleProductNameChange} />
                    </FormItem>

                    <FormItem label="Product Unit">
                            <Select placeholder="Unit" style={{ width: 120 }} value={this.state.unit} onChange={this.handleunitChange} >
                                {this.state.productunits.map(
                                (unt)=>(
                                    <Option value={unt.id}>{unt.unitname}</Option>
                                ))}
                            </Select>
                    </FormItem>


                    <FormItem label="Unit Buying Price"
                    >
                            <Input name="unit_buying_price" type="number"  placeholder="Unit Price" value={this.state.unit_buying_price} onChange={(val)=>{ this.setState({unit_buying_price:val.target.value})}}/>
                    </FormItem>

                    <FormItem label="Unit Selling Price"
                    >
                            <Input name="unit_selling_price" type="number"  placeholder="Unit Price" value={this.state.unit_selling_price} onChange={this.handleunit_selling_priceChange}/>
                    </FormItem>

                    <FormItem label="Description">
                    <Input name="description"  placeholder="Description here" value={this.state.productDescription} onChange={this.handleProductDescriptionChange} />
                    </FormItem>

                    <FormItem>
                    <Button  type="primary" htmlType="submit">Update</Button>
                    </FormItem>
                </Form>


                </Card>  
                </Col>

                </Row>

            </div>
        )

    }

}

export default ProductDetail; 