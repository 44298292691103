import React from 'react';
import { Form, Input, Button,Spin,Select,Card,Result } from 'antd';
import axios from 'axios'
import * as serverconfig from '../../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import PhoneInput from 'react-phone-number-input'

var CryptoJS = require("crypto-js");
const { Option } = Select;

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;


var token= ''


class SaasClientForm extends React.Component {

  state = {
    client_name:'' ,  
    client_phone:'',
    database_name:'',
    datarequested:false,

    client_email:'',
    database_username:'',
    database_password:'',
    address:'' ,  
    datasubmittedsuccessfully:false,
    tinnumber:''
   
  }

  componentDidMount(){
    
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    
    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

  }


  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    let form_data = new FormData();
    form_data.append('client_name', this.state.client_name);
    form_data.append('client_phone', this.state.client_phone);
    form_data.append('client_email', this.state.client_email);

    form_data.append('address', this.state.address);
    form_data.append('tinnumber', this.state.tinnumber);

    axios.post(serverconfig.backendserverurl+'/customqueries/createnew_client', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res => {

    if (res.data.message==="success"){
      this.setState({datarequested:false})
      this.setState({datasubmittedsuccessfully:true})
    }

  })
  .catch(error => console.log(error))

  }

  handlenameChange= (e) => this.setState({ name: e.target.value});
  handleIDDescriptionChange= (e) => this.setState({ idtypeDescription: e.target.value});

  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin  size="large">
                <div className="content" />
              </Spin> 
        </div>
      )

    }else{

      if (this.state.datasubmittedsuccessfully===true){
        return(
            <Card>
            <Result
            status="success"
            title="Successfully created client with database"
            extra={[
              <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
            ]}
            />
            </Card>
        );

      }else{

        return (
          <div>
            <h4>Create new client and database here</h4>
  
      <Form
          onFinish={(event) => this.handleFormSubmit(event)}
      >
        <FormItem label="Client Name"
          name="client_name"
          rules={[
            {
              required: true,
              message: 'Please input client_name',
            },
          ]}
        >
          <Input name="client_name"  placeholder="Put a client_name here." value={this.state.client_name} onChange={(val)=>{this.setState({client_name:val.target.value})}} />
        </FormItem>
  
        <FormItem label="Client Address"
          name="address"
          rules={[
            {
              required: true,
              message: 'Please input address',
            },
          ]}
        >
          <Input name="address"  placeholder="Put a address here." value={this.state.address} onChange={(val)=>{this.setState({address:val.target.value})}} />
        </FormItem>
  
        <FormItem label="Client Phone"
          name="client_phone"
          rules={[
            {
              required: true,
              message: 'Please input client_phone',
            },
          ]}
        >
          <Input name="client_phone"  placeholder="Put a client_phone here." value={this.state.client_phone} onChange={(val)=>{this.setState({client_phone:val.target.value})}} />
        </FormItem>
  
  
        <FormItem label="Client Email"
          name="client_email"
          rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              required: true,
              message: 'Please input client_email',
            },
          ]}
        >
          <Input type="mail" prefix={<MailOutlined className="site-form-item-icon" />} name="client_email"  placeholder="Put a client_email here." value={this.state.client_email} onChange={(val)=>{this.setState({client_email:val.target.value})}} />
        </FormItem>

        <FormItem label="Client tinnumber"
          name="tinnumber"
          rules={[
            {
              required: false,
              message: 'Please input tinnumber',
            },
          ]}
        >
          <Input name="tinnumber"  placeholder="Put a tin number here." value={this.state.tinnumber} onChange={(val)=>{this.setState({tinnumber:val.target.value})}} />
        </FormItem>
  
  
        <FormItem>
          <Button  type="primary" htmlType="submit">{this.props.btnText}</Button>
        </FormItem>
  
        </Form>
          </div>
        );

      }

    }
    
  }

}


export default SaasClientForm;



