import React,{ useRef } from 'react';
import axios from 'axios'
import { Table,Tooltip,Popconfirm,Switch,Popover,Input, Button,Collapse,Card,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Descriptions } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined } from '@ant-design/icons';
import { SearchOutlined,CameraOutlined,PlusOutlined,SaveOutlined,
  MessageOutlined,PlusSquareFilled,EditOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import {
  Col,
  Row,
} from 'reactstrap';
import CurrencyFormat from 'react-currency-format';
import * as serverconfig from '../../serverconn'
import * as reactstrp from 'reactstrap';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

const { TextArea } = Input;
const { Panel } = Collapse;
const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;

var token= ''
var bizuserid=''


message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});


class ReceiptToPrint extends React.Component {


  state = {
    companyprofile:{},
    date:moment().format(dateFormat).toString(),

  };

  componentDidMount(){

    if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
      bizuserid=localStorage.getItem("token")
   }else{
      token= ''
      bizuserid=''
   }

   axios.defaults.headers={
     "Content-Type":"application/json",
     Authorization:`Token ${token}`
   }


    axios.get(`${serverconfig.backendserverurl}/api/app_profile/${1}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })

    })

  }


  render() {
    return (
    <div style={{padding:20}}>
            <Row > 
                <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.company_logo} />
              
                  </Col>
                  <Col xs="6" sm="6" lg="6">
                  <h3>{this.state.companyprofile.system_name}</h3>
                  <h5>{this.state.companyprofile.companydealership}</h5>
                  <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                 
                  </h6>               
                  <h5>Email: {this.state.companyprofile.company_email}</h5>
                  <h5>Website: {this.state.companyprofile.website}</h5>
                  <h5>{this.state.companyprofile.box_number}</h5>
                
                  </Col>
      
                  <Col xs="4" sm="4" lg="4">
                    <p style={{display: 'flex',justifyContent:'center', alignItems:'center'}}>
                    </p>
                    <h1><strong>{this.props.totalTax>0?"TAX INVOICE":"INVOICE"}</strong></h1>
                    <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>TIN:</th>
                            <th>{this.state.companyprofile.tinnumber}</th>
                          </tr>
                        </thead>
                    </reactstrp.Table>
                    <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>Invoice No:</th>
                            <th>{this.props.invoicenumber}</th>
                          </tr>
                          <tr>
                            <th>Invoice Date:</th>
                            <th>{this.state.date}</th>
                          </tr>
                          <tr>
                            <th>Due Amount:</th>
                            <th><CurrencyFormat style={{color:'black'}} value={this.props.netAmount} displayType={'text'} thousandSeparator={true} suffix={'/-'}/></th>
                          </tr>
                        </thead>
                    </reactstrp.Table>

                  </Col>
                          
              </Row>
              <br></br>

              <Row > 
                  <Col xs="6" sm="6" lg="6">
                  <h4>Invoice To:</h4>
                  <reactstrp.Table bordered>
                        <thead>
                          <tr>
                          <th>
                          <h5>&nbsp;&nbsp;{this.props.clientdetails.client_name}</h5>
                          <h5>&nbsp;&nbsp;{this.props.clientdetails.client_phone}</h5>
                          <h5>&nbsp;&nbsp;{this.props.clientdetails.client_email}</h5>
                          <h5>&nbsp;&nbsp;{this.props.clientdetails.address}</h5>
                          <h5>&nbsp;&nbsp;TIN: {this.props.clientdetails.tinnumber}</h5>
                          </th>
                          </tr>
                        </thead>
                  </reactstrp.Table>
                  </Col>
              </Row>
              <br></br>

                     <reactstrp.Table bordered>
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Item</th>
                            <th>Desc</th>
                            <th>Qty</th>
                            <th>Unit</th>
                            <th>Rate</th>
                            <th>Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                        {this.props.receiptitems.map(
                          (item)=>(
                            <tr>
                            <td>{item.itemnumber}</td>
                            <td>{item.item}</td>
                            <td>{item.productdescription}</td>
                            <td>{item.quantity}</td>
                            <td>{item.unit}</td>
                            <td>{<CurrencyFormat value={item.rate} displayType={'text'} thousandSeparator={true}/>}</td>
                            <td>{<CurrencyFormat value={item.amount} displayType={'text'} thousandSeparator={true}/>}</td>
                            </tr>
                          ))}
                          <tr>
                            <td>  
                              {
                              this.props.othercharges.length>0?
                              <div>
                              <h5>Other charges</h5>                  
                              <Table 
                              columns={this.props.otherchargescolumns} 
                              dataSource={this.props.othercharges} 
                              pagination={{hideOnSinglePage:true,responsive:true}}
                              size="small"
                              bordered/>
                              </div>
                              :
                              <br></br>
                            }
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                            <h5>Sub Total: <CurrencyFormat style={{color:'black'}} value={this.props.receipttotal} displayType={'text'} thousandSeparator={true} suffix={'/-'} /></h5>
                            <h5>VAT ({this.props.vatpercentage+" %"}): <CurrencyFormat style={{color:'black'}} value={this.props.totalTax} displayType={'text'} thousandSeparator={true} suffix={'/-'}/></h5>
                            <h5> Other Charges Total:<CurrencyFormat style={{color:'black'}} value={this.props.totalothercharges} displayType={'text'} thousandSeparator={true} suffix={'/-'}/></h5>
                            <h3 style={{backgroundColor:'#D3D3D3',color:'black'}}>&nbsp;Total: <CurrencyFormat style={{color:'black'}} value={this.props.netAmount} displayType={'text'} thousandSeparator={true} suffix={'/-'}/></h3>
                            </td>
                          </tr>

                      </tbody>
                  </reactstrp.Table>
                <br></br>
              <div>
              { ReactHtmlParser(this.props.termsandcons) }
              </div>

              <Row > 
                <Col xs="2" sm="2" lg="2">
              
                </Col>
                <Col xs="6" sm="6" lg="6">
            
                </Col>
    
                <Col xs="4" sm="4" lg="4">
                <p style={{margin:0}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img height="50" width="120"  alt="Logo" src={this.state.companyprofile.document_signature} /></p> 
                <h6 style={{margin:0,paddingTop: -10}}> Signature.....................</h6>
                <p>For {this.state.companyprofile.system_name}</p>
                </Col>
                        
            </Row>
        
          </div>
    );
  }
}

class InvoiceForm extends React.Component {

  state = {
    receiptitems:[],
    item:'',
    quantity:0,
    rate:0,
    amount:0,   
    products:[],
    clients:[],
    date:moment().format(dateFormat).toString(),
    duedate:moment().format(dateFormat).toString(),
    product:'',
    amounttobepaid:0,
    size: 'medium',
    productname:"",
    receipttotal:0,
    amountpaid:0,
    client:'',
    clientdetails:{},
    taxrate:0,
    invoicenumber:'',
    tax:0,
    netAmount:0,
    totalTax:0,
    othercharges:[],
    totalothercharges:0,
    termsandcons:'',
    otherchargesvisible:false,
    emailmodalvisible:false,
    emailbody:'',
    companyprofile:{},
    emailfile:null,
    invoicetype:'',
    pono:'',
    vatpercentage:0,
    clientestimates:[],
    estimate:'',
    productdescription:"",
    itemnumber:0,
    units:[],
    unit:'',
    editvisible:false,
    editableid:'',

    pricecategories:[],
    pricecategory:'',
    productobject:{},

    itemupdate:false,
    selectedupdateitem:{},
    requestsent:true,


    editablesellingprice:false,
    userprofile:{},
    editedsellingprice:0


  };

  callback(key) {
    console.log(key);
  }


  componentDidMount(){

    if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
      bizuserid=localStorage.getItem("bizuserid")
   }else{
      token= ''
      bizuserid=''
   }

   axios.defaults.headers={
     "Content-Type":"application/json",
     Authorization:`Token ${token}`
   }

    axios.get(serverconfig.backendserverurl+`/api/productunits/`)
    .then(res => {
        this.setState({
          units:res.data
        })
    }) 

    axios.get(`${serverconfig.backendserverurl}/api/accounts/${bizuserid}`)
    .then(res => {  
        this.setState({
          userprofile: res.data
        })
    })
    
  
    axios.get(serverconfig.backendserverurl+`/api/products/`)
    .then(res => {
        this.setState({
          products:res.data
        })
    })


    axios.get(serverconfig.backendserverurl+`/api/clients/`)
    .then(res => {
        this.setState({
          clients:res.data
        })
    })

    axios.get(`${serverconfig.backendserverurl}/api/app_profile/${1}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })
        this.setState({vatpercentage:res.data.vatpercentage})
    })


      //Now get the last estimate number in db
      let form_data = new FormData();

      axios.post(serverconfig.backendserverurl+`/customqueries/getlastinvoicenumber`,form_data,{
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res => {
      this.setState({
        invoicenumber: Number(res.data.lastnumber)+1
      })
    }
    )
    .catch(error => console.log(error)) 


  }

  //add other charge item on the list
  addOthercharge=(event) =>{
    const newotheritem={
      key:uuid(),
      chargename:event.chargename,
      chargeamount:event.chargeamount,
     }

    //add to the receipt item list
     this.setState({
      othercharges: [...this.state.othercharges, newotheritem]
      });

      message.info("Added "+event.chargename+" to other charges")

    //Hide modal now
    this.setState({ otherchargesvisible: false });

    }



  /////////////////////////////////////////////
  // MODAL METHODS BEGIN HERE

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  //Item addition success
  handleOk = () => {
    if(this.state.quantity===0){
      message.info("The quantity cant be zero")
    }else{

      if (this.state.itemupdate===true){
        this.setState({requestsent:true})

        const elementsIndex = this.state.receiptitems.findIndex(element => element.key == this.state.selectedupdateitem.key )
  
        let newArray = [...this.state.receiptitems]

        newArray[elementsIndex] = {...newArray[elementsIndex], 
          item:this.state.productobject.name,
          productdescription:this.state.productobject.description,
          productid:this.state.productobject.id,
          rate:this.state.editablesellingprice?this.state.editedsellingprice:this.state.productobject.unit_selling_price,
          quantity:Number(this.state.quantity),
          amount:this.calculateamounttobepaid(),
          pricing:this.state.productobject.name,
          unit:this.state.productobject.unitname,
        }
  
        this.setState({
        receiptitems: newArray,
        });
  

        this.setState({itemupdate:false})
        this.setState({requestsent:false})
        this.setState({ visible: false });


      }else{

    //increase item number
    this.setState({itemnumber:(Number(this.state.itemnumber)+1)})

    const newreceiptitem={
      key:uuid(),
      itemnumber:(Number(this.state.itemnumber)+1),
      item:this.state.productobject.name,
      productdescription:this.state.productobject.description,
      productid:this.state.productobject.id,
      rate:this.state.editablesellingprice?this.state.editedsellingprice:this.state.productobject.unit_selling_price,
      quantity:Number(this.state.quantity),
      amount:this.calculateamounttobepaid(),
      pricing:this.state.productobject.name,
      unit:this.state.productobject.unitname,

     }

    //add to the receipt item list
     this.setState({
      receiptitems: [...this.state.receiptitems, newreceiptitem]
      });

      message.info(this.state.productname+' added to receipt');

      this.setState({ visible: false });

      }
    }

    this.setState({editablesellingprice:false})

  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  ///////////////////////////////////////////
  ///// OTHER CHARGES MODAL METHODS
  showOtherchargesModal = () => {
    this.setState({
      otherchargesvisible: true,
    });
  };

  handleotherchargesmodalCancel = () => {
    this.setState({ otherchargesvisible: false });
  };

  ///////////////////////////////////////////////
  ////// EMAIL MODAL METHODS
  //Item addition success
  emailmodalhandleOk = () => {
    this.setState({ emailmodalvisible: false });

    //Proceed and send message here
    let form_data = new FormData();
        form_data.append('receptientemail', this.state.clientdetails.email);
        form_data.append('subject', "Estimate From "+this.state.companyprofile.company_name);
        form_data.append('message', this.state.emailbody);
        form_data.append('file', this.state.emailfile, this.state.emailfile.name);

        //Now submit sale data to database
          axios.post(serverconfig.backendserverurl+'/customqueries/sendemail', form_data, {
            headers: {
              'content-type': 'multipart/form-data'
            }
          })
        .then(res => window.location.reload(false),message.info('Successfully sent email')
        )
        .catch(error => console.log(error)) 
  };

  emailmodalhandleCancel = () => {
    this.setState({ emailmodalvisible: false });
  };

    ///////////////////////////
  //// CREATE EMAILS
  showemailmodal=()=>{
    this.setState({ emailmodalvisible: true });
  }


  /////////////////////////////////////////////////
  // FORM DATA METHODS
  handledatechange= (date, dateString) => this.setState({ date: dateString});
  handleduedatechange= (date, dateString) => this.setState({ duedate: dateString});


  handleclientchange= (e) =>{
    //clear some data
    this.setState({ termsandcons: ''})
    this.setState({ invoicetype: ''})
    this.setState({othercharges:[]})
    this.setState({receiptitems:[]})
    this.setState({estimate:''})

    // set the client
    this.setState({client:e})

    //make query to get client details
    axios.get(`${serverconfig.backendserverurl}/api/clients/${e}`)
    .then(res => {  
        this.setState({
          clientdetails:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/estimates/?client=${e}`)
    .then(res => {
        this.setState({
          clientestimates:res.data
        })
    })

  }

  handleestimatechange= (e) =>{
    // set the client
    this.setState({estimate:e})

    axios.get(`${serverconfig.backendserverurl}/api/estimates/${e}`)
    .then(res => {  
 
      // set the client
      this.setState({ date: res.data.date})
      this.setState({ termsandcons: res.data.termsandcons})

      this.setState({ vatpercentage: res.data.taxpercentage})
      this.setState({ invoicetype: res.data.estimatetype})


        let form_data = new FormData();
        form_data.append('estimatecode', res.data.estimatenumber);

        //query using the code
        axios.post(serverconfig.backendserverurl+'/customqueries/getestimatedetails', form_data, {
          headers: {
            'content-type': 'multipart/form-data'
          }
        })
      .then(res => {
        console.log(res.data.otherchargesreport)
        this.setState({othercharges:JSON.parse(res.data.otherchargesreport)})
        this.setState({receiptitems:JSON.parse(res.data.estimateitemsreport)})

      }
      )
      .catch(error => console.log(error))

    })


  }

  
  handlequantityChange= (e) => this.setState({ quantity: e.target.value});
  handlerateChange= (e) => this.setState({ rate: e.target.value});
  handleamountpaidChange=(e)=>this.setState({amountpaid:e});

  handleinvoicenumberChange= (e) => this.setState({ invoicenumber: e.target.value});
  handletaxChange= (e) => this.setState({ tax: e.target.value});

  handleamounttobepaidChange= (e) =>{
    this.setState({ amounttobepaid: e.target.value});
  } 

  handltermsandconschange=(e,editor)=>{
    this.setState({termsandcons:editor.getData()})
  }

  handleemailbodychange=(e,editor)=>{
    console.log(editor.getData())
    this.setState({emailbody:editor.getData()})
  }

  handleemailfileChange= (e) => this.setState({ emailfile: e.target.files[0]});

  handleinvoicetypeChange= (e) => this.setState({ invoicetype: e});
  handlevatpercentageChange= (e) => this.setState({ vatpercentage: e.target.value});

  handleponoChange=(e)=>this.setState({pono:e.target.value})
  handleproductdescription= (e) => this.setState({ productdescription: e.target.value});

  handleunitChange=(e)=> this.setState({unit:e})

  
  //////////////////////////////////////////
  // OTHER RECEIPT OPERATIONS
  clearReceipt=()=>{
    this.setState({receiptitems:[]})
    this.setState({itemnumber:0})  

  }

  //remove item from general estimate receipt
  removeItem=(id)=>{
    console.log(id)
    this.setState({ receiptitems:  [...this.state.receiptitems.filter(todo => todo.key
      !==id)]});
    this.setState({itemnumber:(Number(this.state.itemnumber)-1)})        
  }

  //remove item from other charges list
  removeOtherchargesItem=(id)=>{
    this.setState({ othercharges:  [...this.state.othercharges.filter(itm => itm.key
      !==id)]});
  }



  handleProductChange= (e) =>{

      this.setState({ product: e});
      // getting the product items
      axios.get(`${serverconfig.backendserverurl}/api/products/${e}`)
      .then(res => {  

          this.setState({unit:res.data.unitname})
          this.setState({productname:res.data.product_name})

          this.setState({
            productobject:res.data
          })

      })

  }


  //create item total
  calculateamounttobepaid=()=>{
    var total=0;
    if (JSON.stringify(this.state.productobject)==="{}"){
      total=0
    }else{
    
      if (this.state.editablesellingprice===true){
        total=Number(this.state.editedsellingprice)*Number(this.state.quantity)

      }else{
        total=Number(this.state.productobject.unit_selling_price)*Number(this.state.quantity)

      }
    
    }
    return total;
  }

  //calculate items total price
  calculateItemsTotalPrice=()=>{
    var total=0;
    this.state.receiptitems.map((item)=>{
      total+=Number(item.amount)
    })
    return total;
  }

    //calculate items total price
    calculateotherchangestotal=()=>{
      var total=0;
      this.state.othercharges.map((item)=>{
        total+=Number(item.chargeamount)
      })
      return total;
    }

  calculateVat=()=>{
    var total=0;
    this.state.receiptitems.map((item)=>{
      total+=Number(item.amount)
    })

    if(this.state.invoicetype==="tax"){
      return (total*((this.state.vatpercentage)/100));

    }else{
      return 0;

    }

  }



  calculateNetTotalPrice=()=>{
    var vat=0
    var total=0;
    var totalothers=0

    this.state.othercharges.map((item)=>{
      totalothers+=Number(item.chargeamount)
    })

    this.state.receiptitems.map((item)=>{
      total+=Number(item.amount)
    })

    if(this.state.invoicetype==="tax"){
      vat=(total*((this.state.vatpercentage)/100));
      return (total+totalothers+vat);
    }else{
      return (total+totalothers);
    }

  }
  
  /////////////////////////////////////////////
  //////////////////////////////////////
  // MAKE FINAL RECEIPT SUBMISSION
  submitSale=()=>{
    
      if(this.state.client===""||this.state.invoicenumber===""||this.state.invoicetype===""){
        message.info("client is missing or Invoice ID missing or invoice type missing")
      }else{
        let form_data = new FormData();
        form_data.append('date', this.state.date);
        form_data.append('duedate', this.state.duedate);
        form_data.append('client', this.state.client);
        form_data.append('invoicenumber', this.state.invoicenumber);
        form_data.append('totalTax', this.calculateVat());
        form_data.append('vatpercentage', this.state.vatpercentage);
        form_data.append('receipttotal', this.calculateamounttobepaid());
        form_data.append('netAmount', this.calculateNetTotalPrice());
        form_data.append('termsandcons', this.state.termsandcons);
        form_data.append('user', localStorage.getItem('username'));
        form_data.append('products', JSON.stringify(this.state.receiptitems));
        form_data.append('othercharges', JSON.stringify(this.state.othercharges));
        form_data.append('invoicetype', this.state.invoicetype);
        form_data.append('purchaseorderno', this.state.pono);

        //Now submit sale data to database
          axios.post(serverconfig.backendserverurl+'/customqueries/createinvoice', form_data, {
            headers: {
              'content-type': 'multipart/form-data'
            }
          })
        .then(res =>{
          if(res.data.message==="Invoice number already exists"){
            message.info(res.data.message)
          }else{
            this.props.history.push("/invoices/")
          }
        } 
        )
        .catch(error => console.log(error)) 
     
      }


  }

  ////////////////////////////////////
  // PRODUCT SEARCH SELECT METHODS
   onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }


  ////////////////////////////////////////////
  // RENDERING METHOD HERE
  render() {

      const columns = [
        {
          title: '#',
          dataIndex: 'itemnumber',
          key: 'key',
        },      
        {
          title: 'ITEM',
          dataIndex: 'item',
          key: 'key',
          render:(text,record)=><div>
          <p><strong>{text}</strong></p>
          <p>{record.productdescription}</p>
          </div>
        },
        {
          title: 'QUANTITY',
          dataIndex: 'quantity',
          key: 'key',
        },
        {
          title: 'UNIT',
          dataIndex: 'unit',
          key: 'key',
        },
        {
          title: 'RATE',
          dataIndex: 'rate',
          key: 'key',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}   />
        },
        {
          title: 'AMOUNT',
          dataIndex: 'amount',
          key: 'key',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'ACTION',
          dataIndex: 'key',
          key: 'key',
          render: (text,record) =>
          <p>
        <Popover content={<p>Click here to edit item</p>} title="Edit Item">
        <EditOutlined style={{color:'blue'}} onClick={(event) => {

              this.setState({itemupdate:true})
              this.setState({requestsent:true})
              this.setState({editablesellingprice:false})

                this.state.receiptitems.map(
                  (item)=>{
                    if(item.key==text){
                       this.setState({quantity:item.quantity});

                        // getting the product items
                        axios.get(`${serverconfig.backendserverurl}/api/products/${record.product}`)
                        .then(res => {  
                            this.setState({
                              productobject:res.data
                            })
                            this.setState({ product: res.data.id});
                            this.setState({unit:res.data.unitname})
                            this.setState({productname:res.data.product_name})
                        })
                  
                    }
                  })

                  this.setState({selectedupdateitem:record})
                  this.setState({requestsent:false})

                  this.setState({visible:true})

        }} />
        </Popover>
        &nbsp;&nbsp;&nbsp;  
        <Popover content={<p>Click here to delete item</p>} title="Delete Item">
        <Popconfirm title="Sure to delete?" onConfirm={() => this.removeItem(text)}>
        <CloseCircleFilled style={{color:'red'}} />
        </Popconfirm>       
         </Popover>
          </p>
        },
  
      ];
  
      const receiptcolumns = [
        {
          title: '#',
          dataIndex: 'itemnumber',
          key: 'key',
        }, 
        {
          title: 'ITEM',
          dataIndex: 'item',
          key: 'key',
          render:(text,record)=><div>
            <p><strong>{text}</strong></p>
            <p>{record.productdescription}</p>
          </div>
        },
        {
          title: 'QUANTITY',
          dataIndex: 'quantity',
          key: 'key',
        },
        {
          title: 'UNIT',
          dataIndex: 'unit',
          key: 'key',
        },
        {
          title: 'RATE',
          dataIndex: 'rate',
          key: 'key',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },
        {
          title: 'AMOUNT',
          dataIndex: 'amount',
          key: 'key',
          render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
        },
  
      ];
  

    const otherchargescolumns = [
      {
        title: 'Item',
        dataIndex: 'chargename',
        key: 'key',
      },
      {
        title: 'Amount',
        dataIndex: 'chargeamount',
        key: 'key',
        render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />

      },
      {
        title: 'Action',
        dataIndex: 'key',
        key: 'key',
        render: text =>
        <p>
        <Popover content={<p>Click here to delete item</p>} title="Delete Item">
        <CloseCircleFilled style={{color:'red'}} onClick={(event) => this.removeOtherchargesItem(text)} />
        </Popover>
        </p>
      }  
    ];

    const otherchargescolumnsprint = [
      {
        title: 'Item',
        dataIndex: 'chargename',
        key: 'key',
      },
      {
        title: 'Amount',
        dataIndex: 'chargeamount',
        key: 'key',
        render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
      },
  
    ];

  
    return(
        <div>
          <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="INVOICE ENTRY" key="1">

            <div style={{overflowX:'auto'}}>
                <reactstrp.Table>
                    <tbody>
                    <tr>
                    
                      <td>
                      <div>
                        <Tooltip title="Click to add Item to List" placement="top">
                        <p style={{display: 'flex',justifyContent:'center', alignItems:'center'}}><PlusSquareFilled style={{ fontSize: '40px', color: '#08c' }} onClick={this.showModal}/></p>
                        <h6 style={{display: 'flex',justifyContent:'center', alignItems:'center'}}>Add Item</h6>
                        </Tooltip>
                      </div>
                      </td>
                      <td>
                      <div >
                        <Tooltip title="Click to clear List" placement="top">
                        <p style={{display: 'flex',justifyContent:'center', alignItems:'center'}}><CloseCircleFilled style={{ fontSize: '40px', color: '#08c' }} onClick={this.clearReceipt}/></p>
                        <h6 style={{display: 'flex',justifyContent:'center', alignItems:'center'}}>Clear List</h6>
                        </Tooltip>
                      </div>
                      </td>

                      <td>
                      <div>
                        <Tooltip title="Add Other Charges" placement="top">
                        <p style={{display: 'flex',justifyContent:'center', alignItems:'center'}}><PlusSquareFilled style={{ fontSize: '40px', color: '#08c' }} onClick={this.showOtherchargesModal}/></p>
                        <h6 style={{display: 'flex',justifyContent:'center', alignItems:'center'}}>Add Other Charges</h6>
                        </Tooltip>
                      </div>
                      </td>                      
                    </tr>
                  
                  </tbody>
                </reactstrp.Table>  
              </div>


            <div style={{display: "none"}}>
            &nbsp;&nbsp;
            <ReceiptToPrint
            receipttotal={this.calculateItemsTotalPrice()} 
            date={this.state.date}
            columns={receiptcolumns}
            receiptitems={this.state.receiptitems}
            othercharges={this.state.othercharges}
            totalothercharges={this.calculateotherchangestotal()}
            termsandcons={this.state.termsandcons}
            totalTax={this.calculateVat()}
            netAmount={this.calculateNetTotalPrice()}
            clientdetails={this.state.clientdetails}
            invoicenumber={this.state.invoicenumber}
            otherchargescolumns={otherchargescolumnsprint}
            vatpercentage={this.state.vatpercentage}
            ref={el => (this.componentRef = el)} /> 
             </div>

                <Row >
                <Col xs="12" sm="6" lg="4">
                <FormItem label="Client(Bill To)">
                <Select placeholder="Client Name" 
                value={this.state.client} 
                onChange={this.handleclientchange} 
                showSearch
                optionFilterProp="children"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onSearch={this.onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                >
                    {this.state.clients.map(
                      (item)=>(
                        <Option value={item.id}>{item.client_name}</Option>
                      ))}
                </Select>
               
                </FormItem>
                <br></br>
{ /*               <FormItem label="Client Proforma">
                <Select placeholder="Proforma" 
                value={this.state.estimate} 
                onChange={this.handleestimatechange} 
                showSearch
                optionFilterProp="children"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onSearch={this.onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                >
                    {this.state.clientestimates.map(
                      (item)=>(
                        <Option value={item.id}>Proforma No: {item.estimatenumber}</Option>
                      ))}
                </Select>
               
                </FormItem> */}


                </Col>

                <Col xs="12" sm="6" lg="4">
                  <h6>Client Details</h6>
                  <p>Name: {this.state.clientdetails.client_name}</p>
                  <p>Contact: {this.state.clientdetails.client_contact}</p>
                  <p>Email: {this.state.clientdetails.email}</p>
                  <p>Tax ID: {this.state.clientdetails.tinnumber}</p>
                </Col>

                <Col xs="12" sm="6" lg="4">

                <FormItem label="Date">
                          <DatePicker defaultValue={moment()} onChange={this.handledatechange} format={dateFormat} />
                </FormItem>

                <FormItem label="Due Date">
                          <DatePicker defaultValue={moment()} onChange={this.handleduedatechange} format={dateFormat} />
                </FormItem>

                <Form  layout="inline" >
                  <p>Invoice&nbsp;&nbsp;</p>

                  <FormItem label="">
                  <Input placeholder="NO." type="number" style={{width: 80}} 
                  value={this.state.invoicenumber} onChange={this.handleinvoicenumberChange}
                  />
                  </FormItem>
                </Form>
                <br></br>
                <FormItem label="Invoice Type">
                  <Select placeholder="Type" style={{ width: 120 }} value={this.state.invoicetype} onChange={this.handleinvoicetypeChange} >                
                        <Option value='tax'>Tax</Option>
                        <Option value='notax'>No Tax</Option>

                  </Select>
                </FormItem>
             
                {
                  this.state.invoicetype=="tax"?
                    <FormItem label="Vat (%)">
                    <Input placeholder="%" style={{width: 80}}
                    value={this.state.vatpercentage} onChange={this.handlevatpercentageChange}
                    />
                  </FormItem>
                  :
                  <br></br>
                }

              <FormItem label="Puchase Order No">
                  <Input placeholder="NO."  
                  value={this.state.pono} onChange={this.handleponoChange}
                  />
              </FormItem>
                
                </Col>
              </Row>


           <br></br>
           <div onMouseOver={this.calculatereceipttotal}>
           <Table 
           columns={columns} 
           dataSource={this.state.receiptitems} 
           scroll={{ x: 1000 }}
           bordered/>
           </div>
            <br></br>
             <div style={{overflowX:'auto'}}>
                <reactstrp.Table>
                    <tbody>
                    <tr>
                    
                      <td>
                      <div>
                        <Tooltip title="Click to add Item to List" placement="top">
                        <p style={{display: 'flex',justifyContent:'center', alignItems:'center'}}><PlusSquareFilled style={{ fontSize: '40px', color: '#08c' }} onClick={this.showModal}/></p>
                        <h6 style={{display: 'flex',justifyContent:'center', alignItems:'center'}}>Add Item</h6>
                        </Tooltip>
                      </div>
                      </td>
                      <td>
                      <div >
                        <Tooltip title="Click to clear List" placement="top">
                        <p style={{display: 'flex',justifyContent:'center', alignItems:'center'}}><CloseCircleFilled style={{ fontSize: '40px', color: '#08c' }} onClick={this.clearReceipt}/></p>
                        <h6 style={{display: 'flex',justifyContent:'center', alignItems:'center'}}>Clear List</h6>
                        </Tooltip>
                      </div>
                      </td>

                      <td>
                      <div>
                        <Tooltip title="Add Other Charges" placement="top">
                        <p style={{display: 'flex',justifyContent:'center', alignItems:'center'}}><PlusSquareFilled style={{ fontSize: '40px', color: '#08c' }} onClick={this.showOtherchargesModal}/></p>
                        <h6 style={{display: 'flex',justifyContent:'center', alignItems:'center'}}>Add Other Charges</h6>
                        </Tooltip>
                      </div>
                      </td>                      
                    </tr>
                  
                  </tbody>
                </reactstrp.Table>  
              </div>



            <div className="animated fadeIn" onMouseOver={this.calculatereceipttotal}>

              <div style={{overflowX:'auto'}}>
                <reactstrp.Table>
                <tbody>
                <tr>
                  {
                    this.state.othercharges.length>0?
                    <td>
                    <h5 style={{color:'red'}}>Other charges</h5>
                    <Table 
                    columns={otherchargescolumns} 
                    dataSource={this.state.othercharges} 
                    pagination={{hideOnSinglePage:true,responsive:true}}
                    bordered/>

                    </td>
                    :
                    <br></br>

                  }
                  
                  <td>
                  <h6>Total: <CurrencyFormat style={{color:'green'}} value={this.calculateItemsTotalPrice()} displayType={'text'} thousandSeparator={true} suffix={'/-'} /></h6>
                  <h6>VAT({this.state.vatpercentage+" %"}): <CurrencyFormat style={{color:'green'}} value={this.calculateVat()} displayType={'text'} thousandSeparator={true} suffix={'/-'}/></h6>
                  <h6> Other Charges(Total):<CurrencyFormat style={{color:'green'}} value={this.calculateotherchangestotal()} displayType={'text'} thousandSeparator={true} suffix={'/-'}/></h6>
                  <h5 style={{backgroundColor:'#C0C0C0',color:'#3B9C9C'}}>&nbsp;Net Amount: <CurrencyFormat style={{color:'black'}} value={this.calculateNetTotalPrice()} displayType={'text'} thousandSeparator={true} suffix={'/-'}/></h5>
                  </td>
                </tr>
              
              </tbody>
              </reactstrp.Table>
              </div>
               
            <br></br>
            <Row >
               
            <Col xs="12" sm="6" lg="6">
              <h3>Terms & Conditions:</h3>
            <Card className="text-black">
           
            <CKEditor
            editor={ClassicEditor}
            onChange={this.handltermsandconschange}
            data="<p><strong>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; TERMS AND CONDITIONS</strong></p>"
            onInit={ editor => {
              console.log( 'Editor is ready to use!', editor );
            } }
         
            >

            </CKEditor>

            </Card>
         
            
            </Col>

            <Col xs="12" sm="6" lg="6">
                        
            <div style={{overflowX:'auto'}}>
            <reactstrp.Table>
                    <tbody>
                    <tr>
                      <td>
                        <a>
                          <ReactToPrint
                            trigger={() =>                     
                              <div>
                                <Tooltip title="Click to save and print Invoice" placement="right">
                                <p style={{display: 'flex',justifyContent:'center', alignItems:'center'}}><PrinterOutlined style={{ fontSize: '40px', color: '#08c' }}/></p>
                                <h6 style={{display: 'flex',justifyContent:'center', alignItems:'center'}}>Print & Save</h6>
                                </Tooltip>
                              </div>                          
                          }
                          content={() => this.componentRef}
                          onAfterPrint={this.submitSale}
                        />  
                        </a>
                    
                      </td>
                      <td>
                      <div>
                        <Tooltip title="Click to save invoice" placement="right">
                        <p style={{display: 'flex',justifyContent:'center', alignItems:'center'}}><SaveOutlined style={{ fontSize: '40px', color: '#08c' }} onClick={this.submitSale}/></p>
                        <h6 style={{display: 'flex',justifyContent:'center', alignItems:'center'}}>Save</h6>
                        </Tooltip>
                      </div>
                      </td>
                  
                    </tr>
                  
                  </tbody>
                </reactstrp.Table>  
              </div>
                      
              </Col>
                   
           </Row>


            </div>

            <Modal
                  style={{display: 'flex',justifyContent:'center', alignItems:'center'}}
                  visible={this.state.visible}
                  title={this.state.itemupdate?"Item Update":" Item Addition"} 
                  width={600}
                  onOk={this.handleOk}
                  onCancel={this.handleCancel}
                  footer={[
                    <Button key="back" onClick={this.handleCancel}>
                      Cancel
                    </Button>,
                    this.state.itemupdate?
                    <Button key="submit" type="primary"  onClick={this.handleOk}>
                      Update
                    </Button>
                    :
                    <Button key="submit" type="primary"  onClick={this.handleOk}>
                      Add
                    </Button> ,
                    ]}
                    >
                    {
                      this.state.itemupdate?
                      null
                      :
                      <FormItem label="Select Item (Product)">
                          <Select
                           placeholder="Item" 
                            value={this.state.product} 
                            onChange={this.handleProductChange} 
                            
                            showSearch
                            optionFilterProp="children"
                            onFocus={this.onFocus}
                            onBlur={this.onBlur}
                            onSearch={this.onSearch}
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }>
                            {this.state.products.map(
                              (prdct)=>(
                                <Option value={prdct.id}>{prdct.product_name}</Option>
                              ))}
                          </Select>
                      </FormItem>

                    }


                      <FormItem label="Quantity">
                            <Input name="quantity" type="number"  placeholder="Quantity" value={this.state.quantity} onChange={this.handlequantityChange}/>
                      </FormItem> 


                      <div style={{display:'flex',flexDirection:'row'}}>
                        <h5>Edit Seeling Price?</h5>
                      <Switch
                      checked={this.state.editablesellingprice}
                      onChange={val=>{
                        this.setState({editablesellingprice:!this.state.editablesellingprice})
                      }}
                      checkedChildren="YES"
                      unCheckedChildren="NO"
                      />
                      </div>
                     

                    {
                      this.state.editablesellingprice===true?
                        <FormItem label="New Selling Price">
                              <Input name="sellingprice" type="number"  placeholder="Selling price" value={this.state.editedsellingprice} onChange={(val)=>{this.setState({editedsellingprice:val.target.value})}}/>
                        </FormItem> 
                      :
                      null
                    }

                      {
                      this.state.itemupdate?
                      null
                      :
                      <h5>RATE: { this.state.editablesellingprice?this.state.editedsellingprice:this.state.productobject.unit_selling_price}</h5>
                      }


                      {
                      this.state.itemupdate?
                      null
                      :
                      <h5>TOTAL: {this.calculateamounttobepaid()}</h5>
                      }
                 

                  </Modal>

                  <Modal
                  visible={this.state.otherchargesvisible}
                  title="Other Charges Form"
                  width={600}
                  onCancel={this.handleotherchargesmodalCancel}
                  footer={[
                    <Button key="back" onClick={this.handleotherchargesmodalCancel}>
                      Cancel
                    </Button>,
                  
                    ]}
                    >
                  <Form layout="inline" 
                        onFinish={(event) => this.addOthercharge(event)}>
                  <FormItem label="Name"
                    name="chargename"
                    rules={[
                      {
                        required: true,
                        message: 'Enter charge name',
                      },
                    ]}
                    
                    >
                      <Input 
                      style={{ width: 80 }} 
                      placeholder="name"  />
                     </FormItem>

                    <FormItem label="Amount"
                      name="chargeamount"
                      rules={[
                        {
                          required: true,
                          message: 'Enter charge amount',
                        },
                      ]}
                      
                      >
                        <InputNumber
                          style={{ width: 100 }} 
                          defaultValue={0.0}
                          formatter={value => `x ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={value => value.replace(/\x\s?|(,*)/g, '')}
                        />    
                                    
                      </FormItem>

                      <FormItem>
                        <Button  type="primary" htmlType="submit">Add</Button>
                      </FormItem>

                  </Form>
                </Modal>



                <Modal
                  style={{display: 'flex',justifyContent:'center', alignItems:'center'}}
                  visible={this.state.emailmodalvisible}
                  title="Customize Email For Client to receive"
                  width={600}
                  onOk={this.emailmodalhandleOk}
                  onCancel={this.emailmodalhandleCancel}
                  footer={[
                    <Button key="back" onClick={this.emailmodalhandleCancel}>
                      Cancel
                    </Button>,
                    <Button key="submit" type="primary"  onClick={this.emailmodalhandleOk}>
                      Send
                    </Button>,
                    ]}
                    >
                      <p>Email:&nbsp;<strong>{this.state.clientdetails.email}</strong></p>
                      <p>Subject:<strong>{"Estimate From "+this.state.companyprofile.company_name}</strong></p>
                      <p>
                      <FormItem label="Estimate Pdf File">
                        <Input  type="file"  placeholder="Pdf File"  onChange={this.handleemailfileChange}/>
                      </FormItem> 

                      </p>

                      <CKEditor
                        editor={ClassicEditor}
                        onChange={this.handleemailbodychange}
                        data={`<p>Hi ${this.state.clientdetails.clientname},</p>
                        <br></br>
                        <br></br>
                        <p>Thank you for giving us the opportunity to serve you.</p>
                        <br></br>
                        <p>Attached is PDF copy for your Estimate.</p>
                        <br></br>
                        <p>We greatly value your business and look forward to serving you again soon!</p>
                        <br></br>
                        <br></br>
                        <p>Best Regards,</p>
                        <p>${this.state.companyprofile.company_name}</p>

                        `}
                        onInit={ editor => {
                          console.log( 'Editor is ready to use!', editor );
                        } }
                    
                        >

                        </CKEditor>

                  </Modal>

            </Panel>

          </Collapse>
                  
        </div>

    )
  }
}

export default InvoiceForm; 
