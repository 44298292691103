import React from 'react';
import { Form, Input, Button,Spin,Select,Card,Result,Switch,DatePicker } from 'antd';
import axios from 'axios'
import * as serverconfig from '../../serverconn'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import PhoneInput from 'react-phone-number-input'

var CryptoJS = require("crypto-js");
const { Option } = Select;

const FormItem=Form.Item;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''
const dateFormat = 'DD/MM/YYYY';


class InstanceUpdateForm extends React.Component {

  state = {
    sacco_name:'' ,  
    RegisteredPhoneNumber1:'',
    datarequested:false,
    company_email:'',
    datasubmittedsuccessfully:false,

    licencedays:0,
    accountslimit:0,

    activated:false,
    usesms:false,
    roundoffvalue:0,
    sharevalue:0,
    smsmessages:0,

    automatedloanrepayment:false,
    smsonautomatedloanrepayment:false,
    loanrepaymentalertdays:0,
    automatedloanreminders:false,
    showgroupaccounts:false,

    sharesfeature:false,
    savings_feature:false,
    fixeddeposit_feature:false,
    loans_feature:false,

    mobilemoneygatewaycode:'',
    smsgatewaycode:'',

    allowaccountmonthlyemailstatements:false,
    email_statements:false,
    automated_accounts:false,
    account_concstring:'',
    last_accountnmumber:0,
    salary_requisition_allowed:false,
    allow_transaction_editing:false,

    last_jounalno:0,
    last_loanno:0,

    automated_transaction_date:false,
    send_loan_balance:false,
    send_savings_balance:false,
    savings_interest_active:false,
    allow_transaction_reversal:false,
    last_licencing_date:'',

    members_portal_active:false,

  }

  componentDidMount(){
    
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    
    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    const { record } = this.props;


    this.setState({
      sacco_name: record.sacco_name || '',
      RegisteredPhoneNumber1: record.RegisteredPhoneNumber1 || '',
      datarequested: record.datarequested || false,
      company_email: record.company_email || '',
      datasubmittedsuccessfully: record.datasubmittedsuccessfully || false,
      licencedays: record.licencedays || 0,
      accountslimit: record.accountslimit || 0,
      activated: record.activated || false,
      usesms: record.usesms || false,
      roundoffvalue: record.roundoffvalue || 0,
      sharevalue: record.sharevalue || 0,
      smsmessages: record.smsmessages || 0,
      automatedloanrepayment: record.automatedloanrepayment || false,
      smsonautomatedloanrepayment: record.smsonautomatedloanrepayment || false,
      loanrepaymentalertdays: record.loanrepaymentalertdays || 0,
      automatedloanreminders: record.automatedloanreminders || false,
      showgroupaccounts: record.showgroupaccounts || false,
      sharesfeature: record.sharesfeature || false,
      savings_feature: record.savings_feature || false,
      fixeddeposit_feature: record.fixeddeposit_feature || false,
      loans_feature: record.loans_feature || false,
      mobilemoneygatewaycode: record.mobilemoneygatewaycode || '',
      smsgatewaycode: record.smsgatewaycode || '',
      allowaccountmonthlyemailstatements: record.allowaccountmonthlyemailstatements || false,
      email_statements: record.email_statements || false,
      automated_accounts: record.automated_accounts || false,
      account_concstring: record.account_concstring || '',
      last_accountnmumber: record.last_accountnmumber || 0,
      salary_requisition_allowed: record.salary_requisition_allowed || false,
      allow_transaction_editing: record.allow_transaction_editing || false,
      last_jounalno: record.last_jounalno || 0,
      last_loanno: record.last_loanno || 0,
      automated_transaction_date: record.automated_transaction_date || false,
      send_loan_balance: record.send_loan_balance || false,
      send_savings_balance: record.send_savings_balance || false,
      savings_interest_active: record.savings_interest_active || false,
      allow_transaction_reversal:record.allow_transaction_reversal || false,
      last_licencing_date:record.last_licencing_date || '',

      members_portal_active: record.members_portal_active || false,

    });



  }


  //submit button pressed
  handleFormSubmit=(event) =>{
    this.setState({datarequested:true})

    let form_data = new FormData();
    var alldata={
      'sacco_name': this.state.sacco_name,
      'RegisteredPhoneNumber1': this.state.RegisteredPhoneNumber1,
      'company_email': this.state.company_email,
      'licencedays': this.state.licencedays,
      'accountslimit': this.state.accountslimit,
      'activated': this.state.activated,
      'usesms': this.state.usesms,
      'roundoffvalue': this.state.roundoffvalue,
      'sharevalue': this.state.sharevalue,
      'smsmessages': this.state.smsmessages,
      'automatedloanrepayment': this.state.automatedloanrepayment,
      'smsonautomatedloanrepayment': this.state.smsonautomatedloanrepayment,
      'loanrepaymentalertdays': this.state.loanrepaymentalertdays,
      'automatedloanreminders': this.state.automatedloanreminders,
      'showgroupaccounts': this.state.showgroupaccounts,
      'sharesfeature': this.state.sharesfeature,
      'savings_feature': this.state.savings_feature,
      'fixeddeposit_feature': this.state.fixeddeposit_feature,
      'loans_feature': this.state.loans_feature,
  
      'mobilemoneygatewaycode': this.state.mobilemoneygatewaycode,
      'smsgatewaycode': this.state.smsgatewaycode,
  
  
      'allowaccountmonthlyemailstatements': this.state.allowaccountmonthlyemailstatements,
      'email_statements': this.state.email_statements,
      'automated_accounts': this.state.automated_accounts,
      'account_concstring': this.state.account_concstring,
      'last_accountnmumber': this.state.last_accountnmumber,
      'salary_requisition_allowed': this.state.salary_requisition_allowed,
  
      'allow_transaction_editing': this.state.allow_transaction_editing,
      'last_jounalno': this.state.last_jounalno,
      'last_loanno': this.state.last_loanno,
  
      'automated_transaction_date': this.state.automated_transaction_date,
      'send_loan_balance': this.state.send_loan_balance,
      'send_savings_balance': this.state.send_savings_balance,
      'savings_interest_active': this.state.savings_interest_active,
      'allow_transaction_reversal':this.state.allow_transaction_reversal,
      'last_licencing_date':this.state.last_licencing_date,
      'members_portal_active':this.state.members_portal_active,

    }   

    form_data.append('instance_id', this.props.instance_id);
    form_data.append('client_id', this.props.client_id);

    form_data.append('alldata', JSON.stringify(alldata));


    axios.post(serverconfig.backendserverurl+'/customqueries/updateinstance', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res => {

    if (res.data.message==="Instance updated successfully"){
      this.setState({datarequested:false})
      this.setState({datasubmittedsuccessfully:true})
    }

  })
  .catch(error => console.log(error))

  }

  handlenameChange= (e) => this.setState({ name: e.target.value});
  handleIDDescriptionChange= (e) => this.setState({ idtypeDescription: e.target.value});

  render(){

    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin  size="large">
                <div className="content" />
              </Spin> 
        </div>
      )

    }else{

      if (this.state.datasubmittedsuccessfully===true){
        return(
            <Card>
            <Result
            status="success"
            title="Successfully updated instance"
            extra={[
              <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
            ]}
            />
            </Card>
        );

      }else{

        return (
          <div>
        <h4>Update instance </h4>
      <Form
          onFinish={(event) => this.handleFormSubmit(event)}
      >
        <FormItem label="Sacco Name">
          <Input name="sacco_name"  placeholder="Put a sacco_name here." value={this.state.sacco_name} onChange={(val)=>{this.setState({sacco_name:val.target.value})}} />
        </FormItem>

  
        <FormItem label="company_email">
          <Input name="company_email"  placeholder="Put a company_email here." value={this.state.company_email} onChange={(val)=>{this.setState({company_email:val.target.value})}} />
        </FormItem>
  

        <FormItem label="Phone contact">
          <Input name="RegisteredPhoneNumber1"  placeholder="Put a phone here." value={this.state.RegisteredPhoneNumber1} onChange={(val)=>{this.setState({RegisteredPhoneNumber1:val.target.value})}} />
        </FormItem>

        <FormItem label="Licence days">
          <Input name="licencedays"  placeholder="Put licencedays here." value={this.state.licencedays} onChange={(val)=>{this.setState({licencedays:val.target.value})}} />
        </FormItem>


        <FormItem label="Members limit">
          <Input name="accountslimit"  placeholder="Put accountslimit here." value={this.state.accountslimit} onChange={(val)=>{this.setState({accountslimit:val.target.value})}} />
        </FormItem>


        <Form.Item label="Is instance active?">
          <Switch
              checked={this.state.activated}
              onChange={val=>{this.setState({activated:val})}}
              checkedChildren="True"
              unCheckedChildren="False"
              />
        </Form.Item>

        <FormItem label="Round off value">
          <Input name="roundoffvalue"  placeholder="Put roundoffvalue here." value={this.state.roundoffvalue} onChange={(val)=>{this.setState({roundoffvalue:val.target.value})}} />
        </FormItem>

        <FormItem label="Share value">
          <Input name="sharevalue"  placeholder="Put sharevalue here." value={this.state.sharevalue} onChange={(val)=>{this.setState({sharevalue:val.target.value})}} />
        </FormItem>

        <h3>SMS feature</h3>
        <Form.Item label="Is sms active?">
          <Switch
              checked={this.state.usesms}
              onChange={val=>{this.setState({usesms:val})}}
              checkedChildren="True"
              unCheckedChildren="False"
              />
        </Form.Item>

        <FormItem label="SMS messages">
          <Input name="smsmessages"  placeholder="Put smsmessages here." value={this.state.smsmessages} onChange={(val)=>{this.setState({smsmessages:val.target.value})}} />
        </FormItem>

        <FormItem label="smsgatewaycode">
          <Input  placeholder="Put smsgatewaycode here." value={this.state.smsgatewaycode} onChange={(val)=>{this.setState({smsgatewaycode:val.target.value})}} />
        </FormItem>

        <h3>Other features</h3>
        <Form.Item label="automatedloanrepayment ?">
          <Switch
              checked={this.state.automatedloanrepayment}
              onChange={val=>{this.setState({automatedloanrepayment:val})}}
              checkedChildren="True"
              unCheckedChildren="False"
              />
        </Form.Item>

        <Form.Item label="smsonautomatedloanrepayment ?">
          <Switch
              checked={this.state.smsonautomatedloanrepayment}
              onChange={val=>{this.setState({smsonautomatedloanrepayment:val})}}
              checkedChildren="True"
              unCheckedChildren="False"
              />
        </Form.Item>

        <FormItem label="Loanrepayment alertdays">
          <Input  placeholder="Put loanrepaymentalertdays here." value={this.state.loanrepaymentalertdays} onChange={(val)=>{this.setState({loanrepaymentalertdays:val.target.value})}} />
        </FormItem>

        <Form.Item label="automatedloanreminders ?">
          <Switch
              checked={this.state.automatedloanreminders}
              onChange={val=>{this.setState({automatedloanreminders:val})}}
              checkedChildren="True"
              unCheckedChildren="False"
              />
        </Form.Item>

        <Form.Item label="showgroupaccounts (Groups) ?">
          <Switch
              checked={this.state.showgroupaccounts}
              onChange={val=>{this.setState({showgroupaccounts:val})}}
              checkedChildren="True"
              unCheckedChildren="False"
              />
        </Form.Item>


        <Form.Item label="sharesfeature?">
          <Switch
              checked={this.state.sharesfeature}
              onChange={val=>{this.setState({sharesfeature:val})}}
              checkedChildren="True"
              unCheckedChildren="False"
              />
        </Form.Item>

        <Form.Item label="savings_feature?">
          <Switch
              checked={this.state.savings_feature}
              onChange={val=>{this.setState({savings_feature:val})}}
              checkedChildren="True"
              unCheckedChildren="False"
              />
        </Form.Item>


        <Form.Item label="fixeddeposit_feature?">
          <Switch
              checked={this.state.fixeddeposit_feature}
              onChange={val=>{this.setState({fixeddeposit_feature:val})}}
              checkedChildren="True"
              unCheckedChildren="False"
              />
        </Form.Item>


        <Form.Item label="loans_feature?">
          <Switch
              checked={this.state.loans_feature}
              onChange={val=>{this.setState({loans_feature:val})}}
              checkedChildren="True"
              unCheckedChildren="False"
              />
        </Form.Item>


        <FormItem label="mobilemoneygatewaycode">
          <Input  placeholder="Put mobilemoneygatewaycode here." value={this.state.mobilemoneygatewaycode} onChange={(val)=>{this.setState({mobilemoneygatewaycode:val.target.value})}} />
        </FormItem>


        <Form.Item label="allowaccountmonthlyemailstatements?">
          <Switch
              checked={this.state.allowaccountmonthlyemailstatements}
              onChange={val=>{this.setState({allowaccountmonthlyemailstatements:val})}}
              checkedChildren="True"
              unCheckedChildren="False"
              />
        </Form.Item>

        <Form.Item label="email_statements?">
          <Switch
              checked={this.state.email_statements}
              onChange={val=>{this.setState({email_statements:val})}}
              checkedChildren="True"
              unCheckedChildren="False"
              />
        </Form.Item>

        <Form.Item label="automated_accounts?">
          <Switch
              checked={this.state.automated_accounts}
              onChange={val=>{this.setState({automated_accounts:val})}}
              checkedChildren="True"
              unCheckedChildren="False"
              />
        </Form.Item>

        <FormItem label="account_concstring">
          <Input  placeholder="Put account_concstring here." value={this.state.account_concstring} onChange={(val)=>{this.setState({account_concstring:val.target.value})}} />
        </FormItem>

        <FormItem label="last_accountnmumber">
          <Input  placeholder="Put last_accountnmumber here." value={this.state.last_accountnmumber} onChange={(val)=>{this.setState({last_accountnmumber:val.target.value})}} />
        </FormItem>

        <Form.Item label="salary_requisition_allowed?">
          <Switch
              checked={this.state.salary_requisition_allowed}
              onChange={val=>{this.setState({salary_requisition_allowed:val})}}
              checkedChildren="True"
              unCheckedChildren="False"
              />
        </Form.Item>

        <Form.Item label="allow_transaction_editing?">
          <Switch
              checked={this.state.allow_transaction_editing}
              onChange={val=>{this.setState({allow_transaction_editing:val})}}
              checkedChildren="True"
              unCheckedChildren="False"
              />
        </Form.Item>

    
        <FormItem label="last_jounalno">
          <Input  placeholder="Put last_jounalno here." value={this.state.last_jounalno} onChange={(val)=>{this.setState({last_jounalno:val.target.value})}} />
        </FormItem>

        <FormItem label="last_loanno">
          <Input  placeholder="Put last_loanno here." value={this.state.last_loanno} onChange={(val)=>{this.setState({last_loanno:val.target.value})}} />
        </FormItem>


        <Form.Item label="automated_transaction_date?">
          <Switch
              checked={this.state.automated_transaction_date}
              onChange={val=>{this.setState({automated_transaction_date:val})}}
              checkedChildren="True"
              unCheckedChildren="False"
              />
        </Form.Item>


        <Form.Item label="send_loan_balance on loan deposit sms?">
          <Switch
              checked={this.state.send_loan_balance}
              onChange={val=>{this.setState({send_loan_balance:val})}}
              checkedChildren="True"
              unCheckedChildren="False"
              />
        </Form.Item>

        <Form.Item label="send_savings_balance on savings deposit sms?">
          <Switch
              checked={this.state.send_savings_balance}
              onChange={val=>{this.setState({send_savings_balance:val})}}
              checkedChildren="True"
              unCheckedChildren="False"
              />
        </Form.Item>

        <Form.Item label="savings_interest_active?">
          <Switch
              checked={this.state.savings_interest_active}
              onChange={val=>{this.setState({savings_interest_active:val})}}
              checkedChildren="True"
              unCheckedChildren="False"
              />
        </Form.Item>


        <Form.Item label="allow_transaction_reversal?">
          <Switch
              checked={this.state.allow_transaction_reversal}
              onChange={val=>{this.setState({allow_transaction_reversal:val})}}
              checkedChildren="True"
              unCheckedChildren="False"
              />
        </Form.Item>


        <FormItem label={"Last licencing date: ("+this.state.last_licencing_date+" )"} >
            <DatePicker  onChange={(date, dateString)=>{this.setState({ last_licencing_date: dateString})}} format={dateFormat} />
        </FormItem>

        <h3>Members' portal </h3>
        <Form.Item label="members_portal_active?">
          <Switch
              checked={this.state.members_portal_active}
              onChange={val=>{this.setState({members_portal_active:val})}}
              checkedChildren="True"
              unCheckedChildren="False"
              />
        </Form.Item>

 
  
        <FormItem>
          <Button  type="primary" htmlType="submit">Update</Button>
        </FormItem>
  
        </Form>
          </div>
        );

      }

    }
    
  }

}


export default InstanceUpdateForm;



