import React from 'react';
import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Modal,Divider,Tabs
  ,Card,Form,Select,InputNumber,Drawer,Descriptions,Popconfirm,message } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,
  EditOutlined,PrinterFilled,
  ShoppingCartOutlined,
  AppstoreAddOutlined,
  DeleteOutlined,
  FundViewOutlined,
  PlusOutlined,
  WalletFilled,
  DollarCircleFilled,
  FileTextFilled
} from '@ant-design/icons';
import QRCode from 'react-qr-code';
import { Link } from 'react-router-dom';
import * as serverconfig from '../../serverconn'
import CurrencyFormat from 'react-currency-format';
import {
  Col,
  Row,
} from 'reactstrap';
import PhoneInput from 'react-phone-number-input'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom'
import InvoicePaymentForm from './InvoicePaymentForm'

import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import ReactToPrint from 'react-to-print';
import * as reactstrp from 'reactstrap';
import moment from 'moment';

const { Panel } = Collapse;
const FormItem=Form.Item;
const { Option } = Select;
const { TextArea } = Input;
const { TabPane } = Tabs;
const dateFormat = 'DD/MM/YYYY';


function callback(key) {
  console.log(key);
}

class ReceiptToPrint extends React.Component {

  state = {
    companyprofile:{},
    date:moment().format(dateFormat).toString(),

  };

  componentDidMount(){
    axios.get(`${serverconfig.backendserverurl}/api/app_profile/${1}`)
    .then(res => {  
        this.setState({
          companyprofile: res.data
        })
    })
  }


  render() {
    return (
      <div style={{padding:20}}>
      <Row > 
          <Col xs="2" sm="2" lg="2">
          <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.company_logo} />
        
            </Col>
            <Col xs="6" sm="6" lg="6">
            <h3>{this.state.companyprofile.company_name}</h3>
            <h5>{this.state.companyprofile.companydealership}</h5>
            <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
            
            </h6>               
            <h5>Email: {this.state.companyprofile.company_email}</h5>
            <h5>Website: {this.state.companyprofile.website}</h5>
            <h5>{this.state.companyprofile.box_number}</h5>
          
            </Col>

            <Col xs="4" sm="4" lg="4">
              <p style={{display: 'flex',justifyContent:'center', alignItems:'center'}}>
              </p>
              <h1><strong>{this.props.totalTax>0?"TAX INVOICE":"INVOICE"}</strong></h1>
              <reactstrp.Table bordered>
                  <thead>
                    <tr>
                      <th>TIN:</th>
                      <th>{this.state.companyprofile.tinnumber}</th>
                    </tr>
                  </thead>
              </reactstrp.Table>
              <reactstrp.Table bordered>
                  <thead>
                    <tr>
                      <th>Invoice No:</th>
                      <th>{this.props.invoicenumber}</th>
                    </tr>
                    <tr>
                      <th>Invoice Date:</th>
                      <th>{this.state.date}</th>
                    </tr>
                    <tr>
                      <th>Due Amount:</th>
                      <th><CurrencyFormat style={{color:'black'}} value={this.props.netAmount} displayType={'text'} thousandSeparator={true} suffix={'/-'}/></th>
                    </tr>
                  </thead>
              </reactstrp.Table>

            </Col>
                    
        </Row>
        <br></br>

        <Row > 
            <Col xs="6" sm="6" lg="6">
            <h4>Invoice To:</h4>
            <reactstrp.Table bordered>
                  <thead>
                    <tr>
                    <th>
                    <h5>&nbsp;&nbsp;{this.props.clientdetails.client_name}</h5>
                    <h5>&nbsp;&nbsp;{this.props.clientdetails.client_phone}</h5>
                    <h5>&nbsp;&nbsp;{this.props.clientdetails.client_email}</h5>
                    <h5>&nbsp;&nbsp;{this.props.clientdetails.address}</h5>
                    <h5>&nbsp;&nbsp;TIN: {this.props.clientdetails.tinnumber}</h5>
                    </th>
                    </tr>
                  </thead>
            </reactstrp.Table>
            </Col>
        </Row>
        <br></br>

        <reactstrp.Table bordered>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Item</th>
                      <th>Desc</th>
                      <th>Qty</th>
                      <th>Unit</th>
                      <th>Rate</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                  {this.props.receiptitems.map(
                    (item)=>(
                      <tr>
                      <td>{item.itemnumber}</td>
                      <td>{item.item}</td>
                      <td>{item.productdescription}</td>
                      <td>{item.quantity}</td>
                      <td>{item.unit}</td>
                      <td>{<CurrencyFormat value={item.rate} displayType={'text'} thousandSeparator={true}/>}</td>
                      <td>{<CurrencyFormat value={item.amount} displayType={'text'} thousandSeparator={true}/>}</td>
                      </tr>
                    ))}
                    <tr>
                      <td>  
                        {
                        this.props.othercharges.length>0?
                        <div>
                        <h5>Other charges</h5>                  
                        <Table 
                        columns={this.props.otherchargescolumns} 
                        dataSource={this.props.othercharges} 
                        pagination={{hideOnSinglePage:true,responsive:true}}
                        size="small"
                        bordered/>
                        </div>
                        :
                        <br></br>
                      }
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>
                      <h5>Sub Total: <CurrencyFormat style={{color:'black'}} value={this.props.receipttotal} displayType={'text'} thousandSeparator={true} suffix={'/-'} /></h5>
                      <h5>VAT ({this.props.vatpercentage+" %"}): <CurrencyFormat style={{color:'black'}} value={this.props.totalTax} displayType={'text'} thousandSeparator={true} suffix={'/-'}/></h5>
                      <h5> Other Charges Total:<CurrencyFormat style={{color:'black'}} value={this.props.totalothercharges} displayType={'text'} thousandSeparator={true} suffix={'/-'}/></h5>
                      <h3 style={{backgroundColor:'#D3D3D3',color:'black'}}>&nbsp;Total: <CurrencyFormat style={{color:'black'}} value={this.props.netAmount} displayType={'text'} thousandSeparator={true} suffix={'/-'}/></h3>
                      </td>
                    </tr>

                </tbody>
            </reactstrp.Table>
          <br></br>
        <div>
        { ReactHtmlParser(this.props.termsandcons) }
        </div>

        <Row > 
          <Col xs="2" sm="2" lg="2">
        
            </Col>
            <Col xs="6" sm="6" lg="6">
        
            </Col>

            <Col xs="4" sm="4" lg="4">
            <p style={{margin:0}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img height="50" width="120"  alt="Logo" src={this.state.companyprofile.document_signature} /></p> 
            <h6 style={{margin:0,paddingTop: -10}}> Signature.....................</h6>
            <p>For {this.state.companyprofile.system_name}</p>
            </Col>
                    
        </Row>
  
    </div>     

    );
  }
}

class InvoiceList extends React.Component {
  formRef = React.createRef();
  state = {
    searchText: '',
    searchedColumn: '',
    invoices: [],
    visible:false,
    editvisible:false,
    selectedid:"",
    invoicedetails:{},
    viewpaymentmodal:false,
    othercharges:[],
    receiptitems:[],
    invoicetotalpayment:0,
    invoicepayments:[],
    client:'',
    date: '',
    notes: '',
    estimatenumber: '',
    termsandcons:'',
    estimatetype:'',
    clientdetails:{},
    showprintermodal:false,
    totalTax:0,
    totalothercharges:0,
    receiptbalance:0,
    receipttotal:0,
    netAmount:0,
    duedate:'',
    invoicenumber:'',
    tax:0,
    discount:0,
    discounttype:'',
    discountAmnt:0,
    netAmount:0,
    otherchargesvisible:false,
    emailmodalvisible:false,
    companyprofile:{},
    emailfile:null,
    invoicetype:'',
    pono:'',
    dueAmount:0,
    amountPaid:0,
    invoicedetails:{},
    invoicetotalpayment:0,
    vatpercentage:0,
    productdescription:"",
    itemnumber:0,
    unpaidinvoices:[],   
    paidinvoices:[],    
    showcostofproductionmodal:false,
    costamount:0,

  };

  componentDidMount(){
    axios.get(serverconfig.backendserverurl+`/api/invoices/`)
    .then(res => {
        this.setState({
          invoices:res.data
        })
    })

    let form_data = new FormData();
    axios.post(serverconfig.backendserverurl+`/customqueries/getunpaidinvoices`,form_data, {
      headers: {
        'content-type': 'multipart/form-data'
          }
        })
    .then(res => {
        this.setState({unpaidinvoices:JSON.parse(res.data.unpaidinvoices)})
        this.setState({paidinvoices:JSON.parse(res.data.paidinvoices)})
    })

}


//show printer modal
///setting up receipt printing details
getinvoicedetails=(id)=>{
  //now proceed to get the data for the product
  axios.get(`${serverconfig.backendserverurl}/api/invoices/${id}`)
  .then(res => {  
       // set the client
      this.setState({client:res.data.client})
      this.setState({ date: res.data.date})
      this.setState({ duedate: res.data.duedate})

      this.setState({ notes: res.data.notes})

      this.setState({ invoicenumber: res.data.invoicenumber})
      this.setState({ termsandcons: res.data.termsandcons})

      this.setState({ invoicetype: res.data.invoicetype})
      this.setState({ vatpercentage: res.data.taxpercentage})
      this.setState({ pono: res.data.purchaseorderno})
      this.setState({ amountPaid: res.data.amountPaid})

      console.log(res.data.invoicetype)
      console.log(res.data.taxpercentage)

        //make query to get client details
        axios.get(`${serverconfig.backendserverurl}/api/clients/${res.data.client}`)
        .then(res => {  
            this.setState({
              clientdetails:res.data
            })
        })

        
        let form_data = new FormData();
        form_data.append('invoicenumber', res.data.invoicenumber);
        form_data.append('clientcompany', localStorage.getItem('companyid'));

        //query using the code
        axios.post(serverconfig.backendserverurl+'/customqueries/getinvoicedetails', form_data, {
          headers: {
            'content-type': 'multipart/form-data'
          }
        })
      .then(res => {
        this.setState({othercharges:JSON.parse(res.data.otherchargesreport)})
        this.setState({receiptitems:JSON.parse(res.data.estimateitemsreport)})
      }
      )
      .catch(error => console.log(error))

    })

    //getting the invoice total payment now
    axios.get(`${serverconfig.backendserverurl}/api/invoicepayments/?invoice=${id}`)
    .then(res => {  
      const totalPaid =res.data.reduce((totalPaid, item) => totalPaid + item.amount, 0);
      this.setState({
        invoicetotalpayment:totalPaid
      })

    })
  

  this.setState({showprintermodal:true})

  if(this.state.invoicetype=="tax"){
    const itemstotal = this.state.receiptitems.reduce((totalItems, item) => totalItems + item.amount, 0);
    const totTax=((this.state.vatpercentage/100)*itemstotal)
    const otherchargestot=this.state.othercharges.reduce((totalOther, item) => totalOther + item.chargeamount, 0);

    this.setState({receipttotal:itemstotal})
    this.setState({receiptbalance:Number(this.state.receipttotal)-Number(this.state.amountpaid)})
    
    //getting net total and total tax
    this.setState({totalTax:totTax})

    //getting other charges total
    this.setState({totalothercharges:otherchargestot})

    //setting the net amount
    this.setState({netAmount:(Number(itemstotal)+Number(otherchargestot))+Number(totTax)})

  }else{
    const itemstotal = this.state.receiptitems.reduce((totalItems, item) => totalItems + item.amount, 0);
    const totTax=0
    const otherchargestot=this.state.othercharges.reduce((totalOther, item) => totalOther + item.chargeamount, 0);

    this.setState({receipttotal:itemstotal})
    this.setState({receiptbalance:Number(this.state.receipttotal)-Number(this.state.amountpaid)})
    
    //getting net total and total tax
    this.setState({totalTax:totTax})

    //getting other charges total
    this.setState({totalothercharges:otherchargestot})

    //setting the net amount
    this.setState({netAmount:(Number(itemstotal)+Number(otherchargestot))+Number(totTax)})

  } 

}

closeprintermodal=()=>{
  this.setState({showprintermodal:false})
}

okprintermodal=()=>{
  this.setState({showprintermodal:false})
}

///calculating over role totals
calculatereceipttotal=()=>{
  if(this.state.invoicetype=="tax"){
    const itemstotal = this.state.receiptitems.reduce((totalItems, item) => totalItems + item.amount, 0);
    const totTax=((this.state.vatpercentage/100)*itemstotal)
    const otherchargestot=this.state.othercharges.reduce((totalOther, item) => totalOther + item.chargeamount, 0);

    this.setState({receipttotal:itemstotal})
    this.setState({receiptbalance:Number(this.state.receipttotal)-Number(this.state.amountpaid)})
    
    //getting net total and total tax
    this.setState({totalTax:totTax})

    //getting other charges total
    this.setState({totalothercharges:otherchargestot})

    //setting the net amount
    this.setState({netAmount:(Number(itemstotal)+Number(otherchargestot))+Number(totTax)})

  }else{
    const itemstotal = this.state.receiptitems.reduce((totalItems, item) => totalItems + item.amount, 0);
    const totTax=0
    const otherchargestot=this.state.othercharges.reduce((totalOther, item) => totalOther + item.chargeamount, 0);

    this.setState({receipttotal:itemstotal})
    this.setState({receiptbalance:Number(this.state.receipttotal)-Number(this.state.amountpaid)})
    
    //getting net total and total tax
    this.setState({totalTax:totTax})

    //getting other charges total
    this.setState({totalothercharges:otherchargestot})

    //setting the net amount
    this.setState({netAmount:(Number(itemstotal)+Number(otherchargestot))+Number(totTax)})

  } 
}

//////////////////////////////////////////
//// add cost of production

showcostofproductionmodal=(id)=>{
  this.setState({selectedid:id})
  this.setState({showcostofproductionmodal:true})

}

closecostofprodmodal=()=>{
  this.setState({showcostofproductionmodal:false})
}

okcostofprodmodal=()=>{

  /////////////////////////////////////////////////////
  //// update the invoice cost of production
  let form_data = new FormData();
      form_data.append('costAmount', this.state.costamount);
      form_data.append('invoiceid', this.state.selectedid);

      //query using the code
        axios.post(serverconfig.backendserverurl+'/customqueries/createcostofproduction', form_data, {
          headers: {
            'content-type': 'multipart/form-data'
          }
        })
      .then(res => window.location.reload(false),message.info('Successfully added cost Amount')
      )
      .catch(error => console.log(error))

    this.setState({showcostofproductionmodal:false})

}

handlecostamountChange= (e) => this.setState({ costamount: e});




 ////////////////////////////////////////////////////
 /// PAYMENT METHODS BEGIN
 showpaymentmodal=(id)=>{
    //now proceed to get the data for the product
    axios.get(`${serverconfig.backendserverurl}/api/invoices/${id}`)
    .then(res => {  
        this.setState({
          invoicedetails:res.data
        })

      })

    //getting the invoice total payment now
    axios.get(`${serverconfig.backendserverurl}/api/invoicepayments/?invoice=${id}`)
        .then(res => {  
          const totalPaid =res.data.reduce((totalPaid, item) => totalPaid + item.amount, 0);
          this.setState({
            invoicetotalpayment:totalPaid
          })

        })

  this.setState({ viewpaymentmodal: true });

 }

 closepaymentmodal = () => {
  this.setState({ viewpaymentmodal: false });
 };



    //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {

     ///first get the estimate code name
     axios.get(`${serverconfig.backendserverurl}/api/invoices/${id}`)
     .then(res => {  

            let form_data = new FormData();
            form_data.append('invoicenumber', res.data.invoicenumber);
            form_data.append('clientcompany', localStorage.getItem('companyid'));

            //query using the code
            axios.post(serverconfig.backendserverurl+'/customqueries/getinvoicedetails', form_data, {
              headers: {
                'content-type': 'multipart/form-data'
              }
            })
          .then(res => {
          
            JSON.parse(res.data.otherchargesreport).map(
              (itm)=>{
                //now delete the other charges
                axios.delete(`${serverconfig.backendserverurl}/api/additionalcharges/${itm.key}`).then(res =>{
                  console.log("Deleted other charges item")
                  }
                  );
              })

              JSON.parse(res.data.estimateitemsreport).map(
              (itm)=>{
                //now delete the estimate items
                axios.delete(`${serverconfig.backendserverurl}/api/invoiceitems/${itm.key}`).then(res =>{
                  console.log("Deleted invoice item")
                  }
                  );
              })

          }
          )
          .catch(error => console.log(error))

          //now delete the estimate
          axios.delete(`${serverconfig.backendserverurl}/api/invoices/${id}`).then(res =>{
          message.info("successfully deleted invoice") 
          window.location.reload(false)
          }
          );

     })

  };


getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};



  render() {
 
    const columns = [
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'id',
        ...this.getColumnSearchProps('date'),
      },
      {
        title: 'Invoice',
        dataIndex: 'invoicenumber',
        key: 'id',
        ...this.getColumnSearchProps('invoicenumber'),
      },

      {
        title: 'Name',
        dataIndex: 'clientname',
        key: 'id',
        ...this.getColumnSearchProps('clientname'),
      },
      {
        title: 'Contact',
        dataIndex: 'clientcontact',
        key: 'id',
        ...this.getColumnSearchProps('clientcontact'),
      },
      {
        title: 'Email',
        dataIndex: 'clientemail',
        key: 'id',
        ...this.getColumnSearchProps('clientemail'),
      },
      {
        title: 'Amount',
        dataIndex: 'netAmount',
        key: 'id',
        render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}   />

      },
      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: text => 
        <p>
        <Popover content={<p>Click here to edit,print,view invoice details</p>} title="Edit Invoice">
        <Link to={`/invoices/${text}`}><EditOutlined style={{color:'blue',':hover':{color:'white'}}} /></Link>
        </Popover>

        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>Click to add payment against invoice</p>} title="Payment">
          <WalletFilled style={{color:'blue'}} onClick={() => this.showpaymentmodal(text)}/>
        </Popover>
        &nbsp;&nbsp;&nbsp;        
          <Popover content={<p>Click to print</p>} title="Print">               
          <PrinterFilled onClick={() => this.getinvoicedetails(text)} style={{color:'blue',':hover':{color:'white'}}}/>        
          </Popover>  

        &nbsp;&nbsp;&nbsp;        
        <Popover content={<p>Click to view payments</p>} title="Payments">    
           <Link to={`/invoicepayments/${text}`}><FileTextFilled style={{color:'blue',':hover':{color:'white'}}} /></Link>
        </Popover> 

          &nbsp;&nbsp;&nbsp;
        <Popover content={<p>Click here to delete invoice</p>} title="Delete">
        <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(text)}>
          <DeleteOutlined style={{color:'red'}}/>
         </Popconfirm>
        </Popover>
        </p>,
      }

    ];

    const unpaidinvoicescolumns = [
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'id',
        ...this.getColumnSearchProps('date'),
      },
      {
        title: 'Invoice',
        dataIndex: 'invoicenumber',
        key: 'id',
        ...this.getColumnSearchProps('invoicenumber'),
      },

      {
        title: 'Name',
        dataIndex: 'clientname',
        key: 'id',
        ...this.getColumnSearchProps('clientname'),
      },
      {
        title: 'Days Due',
        dataIndex: 'duedays',
        key: 'id',
        render:text=>
        <p>{text} days</p>

      },

      {
        title: 'Net Amount',
        dataIndex: 'netAmount',
        key: 'id',
        render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}   />

      },
      {
        title: 'Paid Amount',
        dataIndex: 'totalPaid',
        key: 'id',
        render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}   />

      },
      {
        title: 'Due Amount',
        dataIndex: 'dueAmount',
        key: 'id',
        render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}   />
      },
      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: text => 
        <p>
        <Popover content={<p>Click here to edit,print,view invoice details</p>} title="Edit Invoice">
        <Link to={`/invoices/${text}`}><EditOutlined style={{color:'blue',':hover':{color:'white'}}} /></Link>
        </Popover>

        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>Click to add payment against invoice</p>} title="Payment">
          <WalletFilled style={{color:'blue'}} onClick={() => this.showpaymentmodal(text)}/>
        </Popover>
        &nbsp;&nbsp;&nbsp;        
        <Popover content={<p>Click to view payments</p>} title="Payments">        
        <Link to={`/invoicepayments/${text}`}><FileTextFilled style={{color:'blue',':hover':{color:'white'}}} /></Link>
        </Popover> 

        &nbsp;&nbsp;&nbsp;        
          <Popover content={<p>Click to print</p>} title="Print">               
          <PrinterFilled onClick={() => this.getinvoicedetails(text)} style={{color:'blue',':hover':{color:'white'}}}/>        
          </Popover> 
          &nbsp;&nbsp;&nbsp;
        <Popover content={<p>Click here to delete invoice</p>} title="Delete">
        <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(text)}>
          <DeleteOutlined style={{color:'red'}}/>
         </Popconfirm>
        </Popover>     
        </p>,
      }

    ];

    const paidinvoicescolumns = [
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'id',
        ...this.getColumnSearchProps('date'),
      },
      {
        title: 'Invoice',
        dataIndex: 'invoicenumber',
        key: 'id',
        ...this.getColumnSearchProps('invoicenumber'),
      },

      {
        title: 'Name',
        dataIndex: 'clientname',
        key: 'id',
        ...this.getColumnSearchProps('clientname'),
      },

      {
        title: 'Net Amount',
        dataIndex: 'netAmount',
        key: 'id',
        render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}   />

      },
      {
        title: 'Paid Amount',
        dataIndex: 'totalPaid',
        key: 'id',
        render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}   />

      },
      {
        title: 'Due Amount',
        dataIndex: 'dueAmount',
        key: 'id',
        render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}   />
      },
      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: text => 
        <p>       
          <Popover content={<p>Click to print</p>} title="Print">               
          <PrinterFilled onClick={() => this.getinvoicedetails(text)} style={{color:'blue',':hover':{color:'white'}}}/>        
          </Popover>      
        </p>,
      }

    ];


    const receiptcolumns = [
      {
        title: '#',
        dataIndex: 'itemnumber',
        key: 'key',
      }, 
      {
        title: 'ITEM',
        dataIndex: 'item',
        key: 'key',
        render:(text,record)=><div>
          <p><strong>{text}</strong></p>
          <p>{record.productdescription}</p>
        </div>
      },
      {
        title: 'QUANTITY',
        dataIndex: 'quantity',
        key: 'key',
      },
      {
        title: 'UNIT',
        dataIndex: 'unit',
        key: 'key',
      },
      {
        title: 'RATE',
        dataIndex: 'rate',
        key: 'key',
        render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
      },

      {
        title: 'DISCOUNT',
        dataIndex: 'discount',
        key: 'key',
        render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
      },
      {
        title: 'AMOUNT',
        dataIndex: 'amount',
        key: 'key',
        render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
      },

    ];

    const otherchargescolumnsprint = [
      {
        title: 'Item',
        dataIndex: 'chargename',
        key: 'key',
      },
      {
        title: 'Amount',
        dataIndex: 'chargeamount',
        key: 'key',
        render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}  />
      },
  
    ];


    return(
        <div>
            <Tabs defaultActiveKey="1" type="card">
              <TabPane tab="All Invoices" key="1" >
              <Collapse defaultActiveKey={['1']} onChange={this.callback}>
                <Panel header="ALL INVOICES" key="1">
                <Table   
                columns={columns} 
                pagination={{showQuickJumper:true,showSizeChanger:true }}
                dataSource={this.state.invoices} 
                scroll={{ x: 1000 }}
                bordered/>
                </Panel>
                </Collapse>
                <br></br>  
                <Button type="primary" shape="round"><NavLink to='/makeinvoice/'> <PlusOutlined /> Add Invoice</NavLink></Button>  

              </TabPane>

              <TabPane tab="UnPaid Invoices" key="2" >
              <Collapse defaultActiveKey={['1']} onChange={this.callback}>
                <Panel header="UNPAID INVOICES" key="1">
                <Table   
                columns={unpaidinvoicescolumns} 
                pagination={{showQuickJumper:true,showSizeChanger:true }}
                dataSource={this.state.unpaidinvoices} 
                scroll={{ x: 1000 }}
                bordered/>
                </Panel>
                </Collapse>
                <br></br>  
                <Button type="primary" shape="round"><NavLink to='/makeinvoice/'> <PlusOutlined /> Add Invoice</NavLink></Button>  

              </TabPane>
              <TabPane tab="Paid Invoices" key="3" >
              <Collapse defaultActiveKey={['1']} onChange={this.callback}>
                <Panel header="FULLY PAID INVOICES" key="1">
                <Table   
                columns={paidinvoicescolumns} 
                pagination={{showQuickJumper:true,showSizeChanger:true }}
                dataSource={this.state.paidinvoices} 
                scroll={{ x: 1000 }}
                bordered/>
                </Panel>
                </Collapse>
                <br></br>  
                <Button type="primary" shape="round"><NavLink to='/makeinvoice/'> <PlusOutlined /> Add Invoice</NavLink></Button>  

              </TabPane>

            </Tabs>


            {/* Product details modal */}
            <Modal
                  visible={this.state.viewpaymentmodal}
                  title="Invoice Payment"
                  width={600}
                  onCancel={this.closepaymentmodal}
                  footer={[
                    <Button key="back" onClick={this.closepaymentmodal}>
                      Cancel
                    </Button>,
                    ]}
                    >
                 <Descriptions title="">
                    <Descriptions.Item label="Total"><CurrencyFormat value={this.state.invoicedetails.netAmount} displayType={'text'} thousandSeparator={true}   /></Descriptions.Item>
                    <Descriptions.Item label="Paid Amount"><CurrencyFormat value={this.state.invoicetotalpayment} displayType={'text'} thousandSeparator={true}   /></Descriptions.Item>
                    <Descriptions.Item label="Due Amount"><CurrencyFormat value={Number(this.state.invoicedetails.netAmount)-Number(this.state.invoicetotalpayment)} displayType={'text'} thousandSeparator={true}   /></Descriptions.Item>
                </Descriptions>
                <br></br>
                <InvoicePaymentForm invoiceid={this.state.invoicedetails.id} dueamount={Number(this.state.invoicedetails.netAmount)-Number(this.state.invoicetotalpayment)}/>

              </Modal>

            {/* printer modal */}
            <div onMouseOver={this.calculatereceipttotal}>
            <Modal
                visible={this.state.showprintermodal}
                title="Printer Request"
                width={200}
                onCancel={this.closeprintermodal}
                onOk={this.okprintermodal}

                footer={[
                  <Button key="back" onClick={this.closeprintermodal}>
                    Cancel
                  </Button>,
                  <ReactToPrint
                    trigger={() =>  
                    <Button onClick={this.okprintermodal} type="primary">Yes</Button>        
                  }
                  content={() => this.componentRef}
                  onAfterPrint={this.closeprintermodal}

                  />
                  ]}
                  >
                  <h4>Print?</h4>
                    
             </Modal>
            </div>


             {/* cost of production modal */}
             <div>
            <Modal
                  visible={this.state.showcostofproductionmodal}
                  title="Cost Of Production Form"
                  width={200}
                  onCancel={this.closecostofprodmodal}
                  onOk={this.okcostofprodmodal}

                  footer={[
                    <Button key="back" onClick={this.closecostofprodmodal}>
                      Cancel
                    </Button>,
                    <Button onClick={this.okcostofprodmodal} type="primary">
                    Add
                    </Button>        
               
                    ]}
                    >
                    <FormItem label="Amount"
                      >
                        <InputNumber
                          onChange={this.handlecostamountChange}
                          style={{ width: 150 }} 
                          defaultValue={0.0}
                          formatter={value => `x ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={value => value.replace(/\x\s?|(,*)/g, '')}
                        />    
                                    
                      </FormItem>                      
                    
             </Modal>
            </div>


             <div style={{display: "none"}}  >
            &nbsp;&nbsp;
            <ReceiptToPrint
            receipttotal={this.state.receipttotal} 
            date={this.state.date}
            columns={receiptcolumns}
            receiptitems={this.state.receiptitems}
            othercharges={this.state.othercharges}
            totalothercharges={this.state.totalothercharges}
            notes={this.state.notes}
            termsandcons={this.state.termsandcons}
            totalTax={this.state.totalTax}
            netAmount={this.state.netAmount}
            dueAmount={Number(this.state.netAmount)-Number(this.state.invoicetotalpayment)}
            amountPaid={this.state.invoicetotalpayment}
            clientdetails={this.state.clientdetails}
            invoicenumber={this.state.invoicenumber}
            otherchargescolumns={otherchargescolumnsprint}
            vatpercentage={this.state.vatpercentage}
            ref={el => (this.componentRef = el)} /> 
             </div>

        </div>
    )
  }
}

export default InvoiceList; 
