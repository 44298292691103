import React from 'react';
import { Form, Input,message, Button,Select,Switch,Table,Spin,Collapse,Popconfirm,Popover } from 'antd';
import axios from 'axios'
import * as serverconfig from '../../serverconn'
import * as reactstrp from 'reactstrap';

import { SearchOutlined,
  EditOutlined,
  ShoppingCartOutlined,
  AppstoreAddOutlined,
  DeleteOutlined,
  FundViewOutlined,
  PlusOutlined,
  PrinterOutlined,
  PlusSquareFilled,
  PlusCircleFilled,
  CheckCircleFilled,
  CheckCircleOutlined,
  LoadingOutlined
  
} from '@ant-design/icons';
import uuid from 'react-uuid'
import moment from 'moment';
import {
  Col,
  Row,
} from 'reactstrap';


const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { Panel } = Collapse;
const dateFormat = 'DD/MM/YYYY';

class ProductForm extends React.Component {

  state = {
    productName:'' ,  
    unit:'',
    unit_selling_price:0,
    unit_buying_price:0,
    productDescription:'',
    productSerial:'',
    productImage:null,   
    productunits:[],
    products:[],
    ingredientslist:[],
    ingredientslistnames:[],

    quantity:0,
    productlist:[],
    itemupdate:false,
    selectedupdateitem:{},
    requestsent:true,
    itemnumber:0,

  }

  componentDidMount(){
    axios.get(serverconfig.backendserverurl+`/api/productunits/`)
    .then(res => {
        this.setState({
          productunits:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/products/`)
    .then(res => {
        this.setState({
          products:res.data
        })

        this.setState({requestsent:false})
    })
}

  //remove item
  removeItem=(id)=>{
    console.log(id)
    this.setState({ productlist:  [...this.state.productlist.filter(todo => todo.key
      !==id)]});
  }



  //submit button pressed
  additemtolist=(event) =>{

    if(this.state.productName===""){
      alert("Enter Product Name Please")
    }else if(this.state.unit===""){
      alert("Enter Select Unit")
    }else{
      if (this.state.itemupdate===true){
        this.setState({requestsent:true})
  
        const elementsIndex = this.state.productlist.findIndex(element => element.key == this.state.selectedupdateitem.key )
  
        let newArray = [...this.state.productlist]
  
        newArray[elementsIndex] = {...newArray[elementsIndex], 
          product_name:this.state.productName,
          unit:this.state.unit,
          unit_selling_price:this.state.unit_selling_price,
          unit_buying_price:this.state.unit_buying_price,
          productDescription:this.state.productDescription,
          productSerial:this.state.productSerial,
        }
  
         this.setState({
          productlist: newArray,
          });
  
          this.setState({productName:''});
          this.setState({unit:''});
          this.setState({unit_selling_price:0});
          this.setState({unit_buying_price:0})
    
          this.setState({productDescription:''})
          this.setState({productSerial:''})

          this.setState({itemupdate:false})
          this.setState({requestsent:false})

  
      }else{
        this.setState({requestsent:true})
  
        const newreceiptitem={
          key:uuid(),
          itemnumber:(Number(this.state.itemnumber)+1),
          product_name:this.state.productName,
          unit:this.state.unit,
          unit_selling_price:this.state.unit_selling_price,
          unit_buying_price:this.state.unit_buying_price,
          productDescription:this.state.productDescription,
          productSerial:this.state.productSerial,

         }
    
        //add to the receipt item list
        this.setState({
          productlist: [...this.state.productlist, newreceiptitem]
        });
    
        this.setState({productName:''});
        this.setState({unit:''});
        this.setState({unit_selling_price:0});
        this.setState({unit_buying_price:0})
  
        this.setState({productDescription:''})
        this.setState({productSerial:''})

        this.setState({itemnumber:(Number(this.state.itemnumber)+1)})
  
        this.setState({requestsent:false})
  
      }
    }

  }


  handleProductNameChange= (e) => this.setState({ productName: e.target.value});
  handleunitChange= (e) => this.setState({ unit: e});
  handleunit_selling_priceChange= (e) => this.setState({ unit_selling_price: e.target.value});
  handleProductDescriptionChange= (e) => this.setState({ productDescription: e.target.value});
  handleProductSerialChange= (e) => this.setState({ productSerial: e.target.value});
  handleProductImageChange= (e) => this.setState({ productImage: e.target.files[0]});


  handlequantityChange= (e) => {
    this.setState({quantity:e.target.value})
 }


 onBlur=()=> {
  console.log('blur');
}

 onFocus=()=>{
  console.log('focus');
}

onSearch=(val)=> {
  console.log('search:', val);
}


//handle data submission
handleDatasubmission=()=>{

  if (this.state.productlist.length===0){
    message.info("There are no items in the list")

  }else{

    this.setState({requestsent:true})

    let form_data = new FormData();
    form_data.append('productlist', JSON.stringify(this.state.productlist));
    axios.post(serverconfig.backendserverurl+'/customqueries/batchproductentry', form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
    .then(res =>{
      this.setState({requestsent:false})
      window.location.reload(false)
      message.info("Successfully made batch product entry")
    })
  .catch(error => console.log(error))

  }


}


  render(){

    if(this.state.requestsent===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} size={200} />
        </div>
      );

    }else{

      const productlistcolumns = [
        {
          title: '#',
          dataIndex: 'itemnumber',
          key: 'key',
        },
        {
          title: 'Product Name',
          dataIndex: 'product_name',
          key: 'key',
        },
        {
          title: 'Buying Price',
          dataIndex: 'unit_buying_price',
          key: 'key',
        },
        {
          title: 'Selling Price',
          dataIndex: 'unit_selling_price',
          key: 'key',
        },
        {
          title: 'Action',
          dataIndex: 'key',
          key: 'key',
          render: (text,record) =>
          <p>
          <Popover content={<p>Click here to edit</p>} title="Edit,View">
           <FundViewOutlined style={{color:'blue'}}  onClick={()=>{
              this.setState({itemupdate:true})
              this.setState({requestsent:true})

              this.setState({productName:record.product_name})
              this.setState({unit:record.unit})
              this.setState({unit_selling_price:record.unit_selling_price})
              this.setState({unit_buying_price:record.unit_buying_price})
              this.setState({productDescription:record.productDescription})
              this.setState({productSerial:record.productSerial})

              this.setState({selectedupdateitem:record})

              this.setState({requestsent:false})

            }}/>
          </Popover>
          &nbsp;&nbsp;&nbsp;
          <Popover content={<p>Click here to delete client</p>} title="Delete">
          <Popconfirm title="Sure to delete?" onConfirm={() => this.removeItem(text)}>
            <DeleteOutlined style={{color:'red'}} />
           </Popconfirm>
          </Popover>
          </p>,
        }
  
      ];


      return (
        <div>
          <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="PRODUCT LIST" key="1">
            <Table 
              scroll={{ x: 1000 }}
              columns={productlistcolumns}
              pagination={{showQuickJumper:true,showSizeChanger:true }}
              dataSource={this.state.productlist} bordered/>
              <h3>List Total : ( {this.state.productlist.length} ) Items</h3>
            </Panel>
            </Collapse>

            <br></br>
          <reactstrp.Table bordered>
          <thead>
            <tr>
            <th>
            <Button  type="primary" htmlType='button'  onClick={()=>{
              this.handleDatasubmission()

            }}>SUBMIT PRODUCTS</Button>
            </th>
            </tr>
          </thead>
           </reactstrp.Table>


           <br></br>
          <h3>{this.state.itemupdate?"Update "+this.state.productName : "Add New Item To List"}</h3>
           <br></br>
           <Row > 
           <Col xs="12" sm="8" lg="6">
           <Form
            onFinish={(event) => this.additemtolist(event)}
      >
      <FormItem label="Product Name"
     
      >
        <Input name="productname"  placeholder="Put a name here." value={this.state.productName} onChange={this.handleProductNameChange} />
      </FormItem>
  
      <FormItem label="Product Unit">
                <Select placeholder="Unit" 
                value={this.state.unit} onChange={this.handleunitChange} 
                showSearch
                optionFilterProp="children"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onSearch={this.onSearch}    
                >
                  {this.state.productunits.map(
                    (unt)=>(
                      <Option value={unt.id}>{unt.unitname}</Option>
                    ))}
                </Select>
      </FormItem>
  

      <FormItem label="Unit Buying Price" 
      
      >
              <Input name="unit_buying_price" type="number" placeholder="Unit Price" value={this.state.unit_buying_price} onChange={(val)=>{ this.setState({unit_buying_price:val.target.value})}}/>
      </FormItem>
  
      <FormItem label="Unit Selling Price"
      
      >
              <Input name="unit_selling_price" type="number"  placeholder="Unit Price" value={this.state.unit_selling_price} onChange={this.handleunit_selling_priceChange}/>
      </FormItem>
  
  
      <FormItem label="Description"
  
      >
        <Input name="description"  placeholder="Description here" value={this.state.productDescription} onChange={this.handleProductDescriptionChange} />
      </FormItem> 
  

      <FormItem >
        <Button  type="primary" htmlType="submit">{this.state.itemupdate?" Update":" Add"} </Button>
      </FormItem>
       </Form>
    
     </Col>


      </Row>

        </div>
      );

    }

  }

}


export default ProductForm;



