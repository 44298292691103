import React from 'react';
import ProductUnitForm from '../components/ProductUnitForm'
import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Modal
  ,Card,Form,Select,InputNumber,Drawer,Descriptions,Popconfirm,message } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,
  EditOutlined,
  ShoppingCartOutlined,
  AppstoreAddOutlined,
  DeleteOutlined,
  FundViewOutlined,
  PlusOutlined,
  
} from '@ant-design/icons';
import QRCode from 'react-qr-code';
import { Link } from 'react-router-dom';
import * as serverconfig from '../../serverconn'
import CurrencyFormat from 'react-currency-format';
import {
  Col,
  Row,
} from 'reactstrap';
import PhoneInput from 'react-phone-number-input'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

const FormItem=Form.Item;
const { Option } = Select;
const { TextArea } = Input;


function callback(key) {
  console.log(key);
}

class UnitList extends React.Component {
  formRef = React.createRef();

  state = {
    searchText: '',
    searchedColumn: '',
    units: [],
    visible:false,
    editvisible:false,
    selectedid:"",
    unitdetails:{},
    viewunitdetailsmodal:false,
    unitname:'' ,  

  };

  componentDidMount(){
    axios.get(serverconfig.backendserverurl+`/api/productunits/`)
    .then(res => {
        this.setState({
          units:res.data
        })
    })
}

 /////////////////////////////////////////////
  // PRODUCT FORM MODAL METHODS BEGIN HERE
  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  handleunitnameChange= (e) => this.setState({ unitname: e.target.value});


//////////////////////////////////////////////////////////////////////
/// EDIT PRODUCT MODAL VIEW
showEditUnit=(id)=>{
 
    //now proceed to get the data for the product
    axios.get(`${serverconfig.backendserverurl}/api/productunits/${id}`)
    .then(res => {  
        this.setState({
          unitdetails:res.data
        })

      this.formRef.current.setFieldsValue({
        unitname: res.data.unitname,
        unitdescription: res.data.description,
      });

    })
    this.setState({selectedid:id})

    this.setState({
      editvisible: true,
    });

}

handleeditCancel=()=>{
  this.setState({selectedid:''})
  this.setState({
    editvisible: false,
  });
}

  ///////////////////////////////////////////////////////
//submit button pressed on product update
onFinish=(event) =>{

  let form_data = new FormData();
  form_data.append('unitname', event.unitname);
  form_data.append('description', event.unitdescription);

    //make an update now on the product
    axios.put(`${serverconfig.backendserverurl}/api/productunits/${this.state.selectedid}/`,form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  .then(res => window.location.reload(false))
  .catch(error => console.log(error))
    
  }

  ////////////////////////////////////////
  //Product details view modals show and hide
  //////////////////////////////////
  onproductdetaildrawerClose = () => {
    this.setState({ viewunitdetailsmodal: false });
  };

  showunitdetaildrawer=(id)=>{

    //now proceed to get the data for the product
    axios.get(`${serverconfig.backendserverurl}/api/productunits/${id}`)
    .then(res => {  
        this.setState({
          unitdetails:res.data
        })
    })

    this.setState({ viewunitdetailsmodal: true });

  }

    //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {
    axios.delete(`${serverconfig.backendserverurl}/api/productunits/${id}`).then(res =>{
      window.location.reload(false)
      message.info("successfully deleted unit") 
     }
     );
  };


getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};



  render() {
 
    const columns = [
      {
        title: 'Name',
        dataIndex: 'unitname',
        key: 'id',
        ...this.getColumnSearchProps('unitname'),
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'id',
      },
      {
        title: 'Actions',
        dataIndex: 'id',
        key: 'id',
        render: text => 
        <p>
        <Popover content={<p>Click here to edit Unit</p>} title="Edit Unit">
        <EditOutlined style={{color:'blue',':hover':{color:'white'}}} onClick={() => this.showEditUnit(text)}/>
        </Popover>
        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>Click here to view details</p>} title="Unit Details">
        <FundViewOutlined style={{color:'blue'}} onClick={() => this.showunitdetaildrawer(text)}/>       
        </Popover>
        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>Click here to delete unit</p>} title="Delete">
        <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(text)}>
          <DeleteOutlined style={{color:'red'}}/>
         </Popconfirm>
        </Popover>
        </p>,
      }

    ];

    return(
        <div>
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="PRODUCT UNITS" key="1">
            <Table   
            columns={columns} 
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            dataSource={this.state.units} 
            scroll={{ x: 1000 }}
            bordered/>
            </Panel>
            </Collapse>
            <br></br>    

            <Button type="primary" onClick={this.showModal} shape="round" icon={<PlusOutlined />} size={this.state.size}>
              Add Unit
            </Button>
            

            {/* Product creation form modal */}
            <Modal
                  visible={this.state.visible}
                  title="Unit Creation Form"
                  width={600}
                  onCancel={this.handleCancel}
                  footer={[
                    <Button key="back" onClick={this.handleCancel}>
                      Cancel
                    </Button>,
                    ]}
                    >
                <ProductUnitForm/> 
              </Modal>

            {/* Product edit form modal */}
            <Modal
                  visible={this.state.editvisible}
                  title="Unit Edit Form"
                  width={600}
                  onCancel={this.handleeditCancel}
                  footer={[
                    <Button key="back" onClick={this.handleeditCancel}>
                      Cancel
                    </Button>,
                    ]}
                    >
            <Form
                name="editunitform"
                onFinish={(event) => this.onFinish(event)}
                ref={this.formRef}
            >
              <FormItem label="Unit Name"
                    name="unitname"
                    rules={[
                      {
                        required: true,
                        message: 'Please input name',
                      },
                    ]}
                  >
                    <Input   placeholder="Put a name here." value={this.state.unitname} onChange={this.handleunitnameChange} />
                  </FormItem>
                
                  <FormItem label="Unit Description"
                  name="unitdescription"
                  rules={[
                    {
                      required: true,
                      message: 'Please input description',
                    },
                  ]}
                  
                  >
                      <TextArea
                        placeholder="Unit description"
                        autoSize={{ minRows: 2, maxRows: 6 }}
                      />
                  </FormItem>             
           

              <FormItem>
                <Button  type="primary" htmlType="submit">Update</Button>
              </FormItem>
              </Form>
            </Modal>

            {/* Product details modal */}
            <Modal
                  visible={this.state.viewunitdetailsmodal}
                  title="Product Details"
                  width={600}
                  onCancel={this.onproductdetaildrawerClose}
                  footer={[
                    <Button key="back" onClick={this.onproductdetaildrawerClose}>
                      Cancel
                    </Button>,
                    ]}
                    >
                <Descriptions title="">
                    <Descriptions.Item label="Unit Name">{this.state.unitdetails.unitname}</Descriptions.Item>
                    <Descriptions.Item label="Description">{this.state.unitdetails.description}</Descriptions.Item>
                </Descriptions>
              </Modal>

        </div>
    )
  }
}

export default UnitList; 
