import React from 'react';
import { Form, Input, Button,Select,DatePicker,Spin,Image, Card,Divider,Result, message } from 'antd';
import axios from 'axios'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import moment from 'moment';
import * as serverconfig from '../../serverconn'
import {
  Col,
  Row,
} from 'reactstrap';
import { UserOutlined, LockOutlined, LoadingOutlined,MailOutlined } from '@ant-design/icons';
var CryptoJS = require("crypto-js");


const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;


var token= ''


class FrontendBundleImportForm extends React.Component {

  state = {
    date:moment().format(dateFormat).toString(),
    zipfile:null,
    saccoboject:{},
    bunde_type:'operationdashboard'
  }

  componentDidMount(){

    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    this.setState({datarequested:false})
}


  //submit button pressed
  handleFormSubmit=(event) =>{

    if(this.state.zipfile===null){
      message.info("zip file missing")
    }else{

      this.setState({datarequested:true})

      let form_data = new FormData();
     
      form_data.append('client_id', this.props.client_id);
      form_data.append('bunde_type', this.state.bunde_type);

      this.state.zipfile==null?
      console.log("No zipfile file")
      :
      form_data.append('fileToUpload', this.state.zipfile, this.state.zipfile?this.state.zipfile.name:"");
  
      console.log(form_data)
  
        axios.post(serverconfig.backendserverurl+'/customqueries/importclient_frontendbundle', form_data, {
          headers: {
            'content-type': 'multipart/form-data'
          }
        })
      .then(res =>{
        console.log(res.data)
        this.setState({datarequested:false})
        //window.location.reload(false)
        this.setState({datasubmittedsuccessfully:true})
      }    
      )
      .catch(error => console.log(error))

    }

  }


  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{

      if (this.state.datasubmittedsuccessfully===true){
        return(
            <Card>
            <Result
            status="success"
            title="Successfully Imported frontend bundle."
            extra={[
              <Button  type='primary' onClick={()=>{window.location.reload(false)}}>Finish</Button>,
            ]}
            />
            </Card>
        );

      }else{

            return (
              <div>
                <Form
                    onFinish={(event) => this.handleFormSubmit(event)}
                >
                      
                <div style={{display:'flex',flexDirection:'row'}}>
                <FormItem label={"Frontend bundle zip File"} style={{marginRight:3}}>
                    <Input type="file" accept=".zip" placeholder="Zip File"  
                    onChange={(e) =>{
                      this.setState({ zipfile: e.target.files[0]});
                      if(e.target.files[0]){
                        this.setState({
                          zipfilefile: URL.createObjectURL(e.target.files[0])
                        })
                      }

                    }}/>
                </FormItem> 
                </div>

                <FormItem label="Bundle type">
                <Select placeholder="Bundle type" 
                value={this.state.bunde_type}
                onChange={(val)=>{this.setState({bunde_type:val})}} 
                showSearch
                optionFilterProp="children"
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onSearch={this.onSearch}    
                >
                      <Option value={'operationdashboard'}>{'Operation dashboard'}</Option>
                      <Option value={"membersdashboard"}>{"Members dashboard"}</Option>
                </Select>
                </FormItem>

  
              <FormItem >
                <Button  type='primary' htmlType="submit">Import bundle</Button>
              </FormItem>
      
                </Form>
              </div>
            );
          }
    }
    
  }

}

export default FrontendBundleImportForm;



