import React from 'react';
import ProductForm from '../components/ProductForm'
import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Popconfirm,message,Modal,Form,Select } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,FundViewOutlined } from '@ant-design/icons';
import QRCode from 'react-qr-code';
import { Link } from 'react-router-dom';
import * as serverconfig from '../../serverconn'

const { Panel } = Collapse;
const FormItem=Form.Item;
const { Option } = Select;

function callback(key) {
  console.log(key);
}

class ProductList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    products: [],
    productunits:[],
    currenteditablecolumn: {},
    showeditableform:false,

    product_name:0,
    unit:'',
    sellingprice:0,
    buyingprice:0

  };

  componentDidMount(){
    axios.get(serverconfig.backendserverurl+`/api/products/`)
    .then(res => {
        this.setState({
            products:res.data
        })
    })

    axios.get(serverconfig.backendserverurl+`/api/productunits/`)
    .then(res => {
        this.setState({
          productunits:res.data
        })
    })
}

       //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {
    axios.delete(`${serverconfig.backendserverurl}/api/products/${id}`).then(res =>{
      window.location.reload(false)
      message.info("successfully deleted") 
     }
     );
  };


getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};

  render() {
    const columns = [
      {
        title: 'Product Name',
        dataIndex: 'product_name',
        key: 'id',
        ...this.getColumnSearchProps('product_name'),
        render: (text,record) =><div>
        
        <h6 onClick={()=>{

          console.log(record)
          this.setState({currenteditablecolumn:record})
          this.setState({showeditableform:true})

          this.setState({product_name:record.product_name})
          this.setState({sellingprice:record.unit_selling_price})
          this.setState({buyingprice:record.unit_buying_price})
          this.setState({unit:record.unit})

          }}>{record.product_name}</h6>

        </div>

      },

      {
        title: 'Primary Unit',
        dataIndex: 'unitname',
        key: 'id',
      },
      {
        title: 'Unit Buying Prioce',
        dataIndex: 'unit_buying_price',
        key: 'id',
      },
      {
        title: 'Unit Selling Prioce',
        dataIndex: 'unit_selling_price',
        key: 'id',
      },

      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: text =>
        <p>
        <Popover content={<p>Click here to edit,view details</p>} title="Edit,View">
         <Link to={`/products/${text}`}><FundViewOutlined style={{color:'blue'}}/></Link>
        </Popover>
 
        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>Click here to delete client</p>} title="Delete">
        <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(text)}>
          <DeleteOutlined style={{color:'red'}}/>
         </Popconfirm>
        </Popover>
        </p>,
      }

    ];

 

    return(
        <div>
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="ALL PRODUCTS" key="1">
            <Table  
            scroll={{ x: 1000 }} 
            columns={columns} 
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            dataSource={this.state.products} 
            bordered/>
            </Panel>
            </Collapse>
            <br></br>            
            <Collapse defaultActiveKey={['0']} onChange={callback}>
            <Panel header="Collapse Panel to open Form" key="1">
              <h2>Create a product here</h2>
              <ProductForm 
              requestType="post"
              productID={null}
              btnText="Create"
              /> 
            </Panel>
            </Collapse>

            <Modal
                  visible={this.state.showeditableform}
                  title="Product Edit Form"
                  width={600}
                  onCancel={()=>{
                      this.setState({showeditableform:false})
                  }}

                  footer={[
                    <Button key="back" onClick={()=>{
                        this.setState({showeditableform:false})
                    }}>
                      Cancel
                    </Button>
        
                    ]}
                    >
                 <Form
                  onFinish={(event) => {

                  }}>

                  <FormItem label="Name">
                  <Input placeholder="Product Name"  value={this.state.product_name} onChange={(val)=>{this.setState({product_name:val.target.value})}} />
                  </FormItem>
              
                  <FormItem label="Unit">
                    <Select placeholder="Unit" style={{ width: 120 }} value={this.state.unit} onChange={(val)=>{this.setState({unit:val})}} >
                      {this.state.productunits.map(
                        (unit)=>(
                          <Option value={unit.id}>{unit.unitname}</Option>
                        ))}
                    </Select>
                  </FormItem>

                  <FormItem label="Buying Price">
                  <Input placeholder="Buying Price"  value={this.state.buyingprice} onChange={(val)=>{this.setState({buyingprice:val.target.value})}} />
                  </FormItem>

                  <FormItem label="Selling Price">
                  <Input placeholder="Selling Price"  value={this.state.sellingprice} onChange={(val)=>{this.setState({sellingprice:val.target.value})}} />
                  </FormItem>

                  <FormItem>
                  <Button  type='primary' htmlType='button'
                  onClick={()=>{
                     
                    let form_data = new FormData();

                    form_data.append('product_name', this.state.product_name);
                    form_data.append('unit', this.state.unit);
                    form_data.append('unit_selling_price', this.state.sellingprice);
                    form_data.append('unit_buying_price', this.state.buyingprice);
                
                    form_data.append('description', this.state.currenteditablecolumn.productDescription);
                    form_data.append('product_serial', this.state.currenteditablecolumn.productSerial);

                    axios.put(serverconfig.backendserverurl+`/api/products/${this.state.currenteditablecolumn.id}/`, form_data, {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                    })
                    .then(res => {
                      axios.get(serverconfig.backendserverurl+`/api/products/`)
                      .then(res => {
                          this.setState({
                              products:res.data
                          })
                      })
                  
                      axios.get(serverconfig.backendserverurl+`/api/productunits/`)
                      .then(res => {
                          this.setState({
                            productunits:res.data
                          })

                          this.setState({showeditableform:false})

                      })

                    }
                    )
                    .catch(error => console.log(error))

                  }}
                  
                  >Update</Button>
                  </FormItem>
                 </Form>
            
             </Modal>

        </div>
    )
  }
}

export default ProductList; 
