import React from 'react';
import axios from 'axios'
import { Table, Input, Button,Popconfirm,Popover,
  Collapse,Avatar,Drawer,Descriptions,message,Modal,Spin,Switch,Tabs} from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,FundOutlined,FundViewOutlined,DeleteOutlined,LockFilled,LockOutlined,LoadingOutlined } from '@ant-design/icons';
import { UserOutlined } from '@ant-design/icons';
import { Form,Select,Checkbox,Upload } from 'antd';
import { Link } from 'react-router-dom';
import * as serverconfig from '../../serverconn'
import SaasClientForm from '../components/SaasClientForm'
import InstanceUpdateForm from '../components/InstanceUpdateForm'
import InstanceCreationForm from '../components/InstanceCreationForm'
import InstanceUserCreationForm from '../components/InstanceUserCreationForm'
import InstanceAccountList from './InstanceAccountsListView'
import InstanceSMSSetupForm from '../components/InstanceSMSSetupForm'


var CryptoJS = require("crypto-js");
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { TabPane } = Tabs;

const { Panel } = Collapse;
const FormItem=Form.Item;

function callback(key) {
  console.log(key);
}

message.config({
  top: 200,
  duration: 2,
  maxCount: 3,
  rtl: true,
});

var username=''
var token= ''

class ClientInstanceList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    allinstances: [],
    current_selected_instance:'',
    loan_particular_data_loading:false

  };

  displaypasswordupdatemodal=(id)=>{
    this.setState({showpasswordupdatemodal:true})
    this.setState({selectedid:id})
  }
  
  closepasswordupdatemodal=()=>{
    this.setState({showpasswordupdatemodal:false})
  }
  

  //Item addition success
  onresetdrawerClose = () => {
    this.setState({ rightsmodalvisible: false });
  };



  componentDidMount(){

    if(localStorage.getItem("username")){
       username= CryptoJS.AES.decrypt(localStorage.getItem("username") , 'my-secret-key@123').toString(CryptoJS.enc.Utf8)
    }else{
       username=''
    }
    
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    axios.get(serverconfig.backendserverurl+`/customqueries/getallinstances/${this.props.client_id}`)
    .then(res => {
        this.setState({
          allinstances: JSON.parse(res.data.report)  
        })

        this.setState({datarequested:false})

    })



}


  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  render() {
    const columns = [

      {
        title: 'Name',
        dataIndex: 'sacco_name',
        key: 'id',
        ...this.getColumnSearchProps('sacco_name'),
      },
      {
        title: 'Email address',
        dataIndex: 'company_email',
        key: 'id',
        ...this.getColumnSearchProps('company_email'),
      },
      {
        title: 'Phone one',
        dataIndex: 'RegisteredPhoneNumber1',
        key: 'id',
        ...this.getColumnSearchProps('RegisteredPhoneNumber1'),
      },
      {
        title: 'SMS messages',
        dataIndex: 'smsmessages',
        key: 'id',
      },
      {
        title: 'Licence days',
        dataIndex: 'licencedays',
        key: 'id',
      },
      {
        title: 'Members limit',
        dataIndex: 'accountslimit',
        key: 'id',
      },
      {
        title: 'Active',
        dataIndex: 'activated',
        key: 'id',
        ...this.getColumnSearchProps('smsmessages'),
        render: (text,record) =>
        <p>
          {record.activated===true?"Yes":"No"}
        </p>
      }
      
    ];

    if(this.state.datarequested===true){
      return(<div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin  size="large">
                <div className="content" />
              </Spin>       
        </div>)
    }else{

      return(
        <div>
           <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="ALL INSTANCES" key="1">
            <Table 
             scroll={{ x: 1000 }}
             columns={columns}
             pagination={{showQuickJumper:true,showSizeChanger:true }}
             dataSource={this.state.allinstances} 
             bordered

             expandable={{
              expandedRowRender: (record) =>{
                if (Number(record.id)===Number(this.state.current_selected_instance)){
                  return (
                    <div
                      style={{
                        margin: 0,
                      }}
                     >
    
                      {
                        this.state.loan_particular_data_loading===true?
                        <div style={{display:'flex',margin:20}}>
                        <Spin size="large">
                          <div className="content" />
                        </Spin>         
                        </div>
                        :
    
                        <Tabs defaultActiveKey="1" onChange={callback}>
                          <TabPane tab="Details/Update" key="01" >
                            <InstanceUpdateForm instance_id={record.id} client_id={this.props.client_id}    record={record} />       
                          </TabPane>

                          <TabPane tab="Instance users" key="02" >

                          <InstanceAccountList instance_id={record.id} client_id={this.props.client_id} />
                          <br/>
                          <InstanceUserCreationForm instance_id={record.id} client_id={this.props.client_id} />
                         
                          </TabPane>


                          <TabPane tab="SMS gateway" key="03" >
                            <InstanceSMSSetupForm instance={record} instance_id={record.id} client_id={this.props.client_id} />


                          </TabPane>

                         </Tabs>
                      }
                    </div>
                    );
                }else{
                  return null;
                }
              } ,
              rowExpandable: (record) => record.id !== this.state.current_selected_instance.id,
              onExpand:(condition,record)=>{
                this.setState({loan_particular_data_loading:true})
                this.setState({current_selected_instance:record.id})
                this.setState({loan_particular_data_loading:false})
              },
            }}
             />
            <br/>
            <InstanceCreationForm client_id={this.props.client_id} />

            </Panel>
            </Collapse>

        </div>
    )
    }
   
  }
}

export default ClientInstanceList; 
