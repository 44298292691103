import React from 'react';
import axios from 'axios'
import { Table, Input, Button,Collapse,Popover,Modal,Divider,Tabs
  ,Card,Form,Select,InputNumber,Drawer,Descriptions,Popconfirm,message } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,
  EditOutlined,PrinterFilled,
  ShoppingCartOutlined,
  AppstoreAddOutlined,
  DeleteOutlined,
  FundViewOutlined,
  PlusOutlined,
  WalletFilled,
  DollarCircleFilled,
  FileTextFilled
} from '@ant-design/icons';
import QRCode from 'react-qr-code';
import { Link } from 'react-router-dom';
import * as serverconfig from '../../serverconn'
import CurrencyFormat from 'react-currency-format';
import {
  Col,
  Row,
} from 'reactstrap';
import PhoneInput from 'react-phone-number-input'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import { NavLink } from 'react-router-dom'

import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import ReactToPrint from 'react-to-print';
import * as reactstrp from 'reactstrap';
import moment from 'moment';

const { Panel } = Collapse;
const FormItem=Form.Item;
const { Option } = Select;
const { TextArea } = Input;
const { TabPane } = Tabs;
const dateFormat = 'DD/MM/YYYY';

var token= ''
var bizuserid=''


function callback(key) {
  console.log(key);
}

class ReceiptToPrint extends React.Component {

  state = {
    companyprofile:{},
    date:moment().format(dateFormat).toString(),

  };

  componentDidMount(){

    if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
      bizuserid=localStorage.getItem("token")
   }else{
      token= ''
      bizuserid=''
   }

   axios.defaults.headers={
     "Content-Type":"application/json",
     Authorization:`Token ${token}`
   }


   axios.get(`${serverconfig.backendserverurl}/api/app_profile/${1}`)
   .then(res => {  
        this.setState({
          companyprofile: res.data
        })
    })
  }


  render() {
    return (
      <div style={{padding:20}}>
      <Row > 
          <Col xs="2" sm="2" lg="2">
            <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.company_logo} />
        
            </Col>
            <Col xs="6" sm="6" lg="6">
            <h3>{this.state.companyprofile.system_name}</h3>
            <h5>{this.state.companyprofile.companydealership}</h5>
            <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
           
            </h6>               
            <h5>Email: {this.state.companyprofile.company_email}</h5>
            <h5>Website: {this.state.companyprofile.website}</h5>
            <h5>{this.state.companyprofile.box_number}</h5>
          
            </Col>

            <Col xs="4" sm="4" lg="4">
              <p style={{display: 'flex',justifyContent:'center', alignItems:'center'}}>
              </p>
              <h1><strong>INVOICE PAYMENT RECEIPT</strong></h1>
              <reactstrp.Table bordered>
                  <thead>
                    <tr>
                      <th>TIN:</th>
                      <th>{this.state.companyprofile.tinnumber}</th>
                    </tr>
                  </thead>
              </reactstrp.Table>
              <reactstrp.Table bordered>
                  <thead>
                    <tr>
                      <th>Receipt No:</th>
                      <th>{this.props.selected_payment.payemntreceiptno}</th>
                    </tr>
                    <tr>
                      <th>Receipt Date:</th>
                      <th>{this.props.selected_payment.date}</th>
                    </tr>
                  </thead>
              </reactstrp.Table>

            </Col>
                    
        </Row>
        <br></br>

        <Row > 
            <Col xs="6" sm="6" lg="6">
            <h4>Receipt To:</h4>
            <reactstrp.Table bordered>
                  <thead>
                    <tr>
                    <th>
                    <h5>&nbsp;&nbsp;{this.props.clientdetails.client_name}</h5>
                    <h5>&nbsp;&nbsp;{this.props.clientdetails.client_phone}</h5>
                    <h5>&nbsp;&nbsp;{this.props.clientdetails.client_email}</h5>
                    <h5>&nbsp;&nbsp;{this.props.clientdetails.address}</h5>
                    <h5>&nbsp;&nbsp;TIN: {this.props.clientdetails.tinnumber}</h5>
                    </th>
                    </tr>
                  </thead>
            </reactstrp.Table>
            </Col>
        </Row>
        <br></br>

        <reactstrp.Table bordered>
                  <thead>
                  <tr>
                      <th>INVOICE TO</th>
                      <th>{this.props.invoiceobj.invoicenumber}</th>
                    </tr>
                    <tr>
                      <th>AMOUNT PAID</th>
                      <th><CurrencyFormat value={this.props.selected_payment.amount} displayType={'text'} thousandSeparator={true}   /></th>
                    </tr>

                    <tr>
                      <th>REASON</th>
                      <th>{this.props.selected_payment.reasonforpayment}</th>
                    </tr>

                    <tr>
                      <th>INVOICE TOTAL PAID</th>
                      <th><CurrencyFormat value={this.props.invoicetotalpayment} displayType={'text'} thousandSeparator={true}   /></th>

                    </tr>

                    <tr>
                      <th>INVOICE TOTAL BALANCE</th>
                      <th><CurrencyFormat value={Number(this.props.invoiceobj.netAmount)-Number(this.props.invoicetotalpayment)} displayType={'text'} thousandSeparator={true}   /></th>

                    </tr>

                  </thead>
                  <tbody>
                  
                </tbody>
            </reactstrp.Table>
          <br></br>
        <div>
        { ReactHtmlParser(this.props.termsandcons) }
        </div>

        <Row > 
          <Col xs="2" sm="2" lg="2">
        
            </Col>
            <Col xs="6" sm="6" lg="6">
        
            </Col>

            <Col xs="4" sm="4" lg="4">
            {
                this.state.companyprofile.document_signature===null?
                null:
                <p style={{margin:0}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<img height="50" width="120"  alt="Logo" src={this.state.companyprofile.document_signature} /></p> 

              }          
               <h6 style={{margin:0,paddingTop: -10}}> Signature.....................</h6>
            <p>For {this.state.companyprofile.company_name}</p>
            </Col>
                    
        </Row>
  
    </div>     

    );
  }
}

class InvoicePaymentsList extends React.Component {
  formRef = React.createRef();
  state = {
    searchText: '',
    searchedColumn: '',
    invoices: [],
    visible:false,
    editvisible:false,
    selectedid:"",
    invoicedetails:{},
    viewpaymentmodal:false,
    othercharges:[],
    receiptitems:[],
    invoicetotalpayment:0,
    invoicepayments:[],
    client:'',
    date: '',
    notes: '',
    estimatenumber: '',
    termsandcons:'',
    estimatetype:'',
    clientdetails:{},
    showprintermodal:false,
    totalTax:0,
    totalothercharges:0,
    receiptbalance:0,
    receipttotal:0,
    netAmount:0,
    duedate:'',
    invoicenumber:'',
    tax:0,
    discount:0,
    discounttype:'',
    discountAmnt:0,
    netAmount:0,
    otherchargesvisible:false,
    emailmodalvisible:false,
    companyprofile:{},
    emailfile:null,
    invoicetype:'',
    pono:'',
    dueAmount:0,
    amountPaid:0,
    invoicedetails:{},
    invoicetotalpayment:0,
    vatpercentage:0,
    productdescription:"",
    itemnumber:0,
    unpaidinvoices:[],   
    paidinvoices:[],    
    showcostofproductionmodal:false,
    costamount:0,
    selected_payment:{},
    invoiceobj:{},

  };

  componentDidMount(){


    if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
      bizuserid=localStorage.getItem("token")
   }else{
      token= ''
      bizuserid=''
   }

   axios.defaults.headers={
     "Content-Type":"application/json",
     Authorization:`Token ${token}`
   }


   axios.get(`${serverconfig.backendserverurl}/api/app_profile/${1}`)
   .then(res => {  
        this.setState({
          companyprofile: res.data
        })
    })


    const invoiceID=this.props.match.params.invoiceID;

    axios.get(`${serverconfig.backendserverurl}/api/invoices/${invoiceID}`)
    .then(res => { 
      this.setState({invoiceobj:res.data})

      //make query to get client details
      axios.get(`${serverconfig.backendserverurl}/api/clients/${res.data.client}`)
      .then(res => {  
          this.setState({
            clientdetails:res.data
          })
      })

    })


    //getting the invoice total payment now
    axios.get(`${serverconfig.backendserverurl}/api/invoicepayments/?invoice=${invoiceID}`)
    .then(res => {  
      this.setState({invoicepayments:res.data})

      const totalPaid =res.data.reduce((totalPaid, item) => totalPaid + item.amount, 0);
      this.setState({
        invoicetotalpayment:totalPaid
      })

    })

}


//////////////////////////////////////////////////
//handle delete here
handleDelete = id => {
    ///first get the estimate code name
    axios.delete(`${serverconfig.backendserverurl}/api/invoicepayments/${id}`)
    .then(res => {  
    message.info("Successfully deleted")
    window.location.reload(false)
    }
    )
    .catch(error => console.log(error))
};


getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={node => {
          this.searchInput = node;
        }}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Button
        type="primary"
        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
        icon={<SearchOutlined />}
        size="small"
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => this.searchInput.select());
    }
  },
  render: text =>
    this.state.searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : (
      text
    ),
});

handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  this.setState({
    searchText: selectedKeys[0],
    searchedColumn: dataIndex,
  });
};

handleReset = clearFilters => {
  clearFilters();
  this.setState({ searchText: '' });
};



  render() {
 
    const columns = [
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'id',
        ...this.getColumnSearchProps('date'),
      },
      {
        title: 'Invoice no',
        dataIndex: 'invoiceno',
        key: 'id',
        ...this.getColumnSearchProps('invoiceno'),
      },

      {
        title: 'Receipt no',
        dataIndex: 'payemntreceiptno',
        key: 'id',
        ...this.getColumnSearchProps('payemntreceiptno'),
      },
      {
        title: 'Amount paid',
        dataIndex: 'amount',
        key: 'id',
        render:text=><CurrencyFormat value={text} displayType={'text'} thousandSeparator={true}   />
      },

    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (text,record) => 
      <p>
      <Popover content={<p>Click to print</p>} title="Print">               
      <PrinterFilled onClick={()=>{
        this.setState({selected_payment:record})
        this.setState({showprintermodal:true})
    
      }} style={{color:'blue',':hover':{color:'white'}}}/>    
      </Popover>  

        &nbsp;&nbsp;&nbsp;
      <Popover content={<p>Click here to delete invoice</p>} title="Delete">
      <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(text)}>
        <DeleteOutlined style={{color:'red'}}/>
      </Popconfirm>
      </Popover>
      </p>,
    }

    ];

    
    return(
        <div>

          <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="INVOICE PAYMENTS" key="1">
            <Table   
            columns={columns} 
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            dataSource={this.state.invoicepayments} 
            scroll={{ x: 1000 }}
            bordered/>

            <h4>Total paid: {this.state.invoicetotalpayment}</h4>

            </Panel>
            </Collapse>


             <div style={{display: "none"}}  >
            &nbsp;&nbsp;
            <ReceiptToPrint
            receipttotal={this.state.receipttotal} 
            date={this.state.date}
            selected_payment={this.state.selected_payment}
            invoiceobj={this.state.invoiceobj}
            clientdetails={this.state.clientdetails}
            invoicetotalpayment={this.state.invoicetotalpayment}

            ref={el => (this.componentRef = el)} /> 
             </div>


             <Modal
                  
                  visible={this.state.showprintermodal}
                  title="Printer Request"
                  width={200}
                  onCancel={(val)=>{this.setState({showprintermodal:false})}}  
                  footer={[
                    <Button key="back" onClick={(val)=>{this.setState({showprintermodal:false})}}>
                      Cancel
                    </Button>,
                    <ReactToPrint
                      trigger={() =>  
                      <Button onClick={(val)=>{this.setState({showprintermodal:false})}} type="primary">Yes</Button>        
                    }
                    content={() => this.componentRef}
                    onAfterPrint={(val)=>{this.setState({showprintermodal:false})}}
  
                    />
                    ]}
                    >
                    <h4>Print?</h4>
                      
               </Modal>

        </div>
    )
  }
}

export default InvoicePaymentsList; 
