import React from 'react';
import { Form, Input, Button,Select,Checkbox,Upload,Spin,Switch } from 'antd';
import axios from 'axios'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { UserOutlined, LockOutlined, LoadingOutlined, MailOutlined,UploadOutlined } from '@ant-design/icons';
import * as serverconfig from '../../serverconn'
import Resizer from "react-image-file-resizer";

const FormItem=Form.Item;
const { Option } = Select;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''

class InstanceAccountUpdateForm extends React.Component {

  state = {
    datarequested:false,
    userprofile:{},
    is_active:false,
    branch_manager:false,
    username:'',
    email:''
  }


  componentDidMount(){
    if(localStorage.getItem("token")){
       token= localStorage.getItem("token")
    }else{
       token= ''
    }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    this.setState({datarequested:false})

    this.setState({
      username: this.props.record.username || '',
      email:  this.props.record.email || '',
      is_active:  this.props.record.is_active || false,
      branch_manager:  this.props.record.branch_manager || '',
     
    });



  }


  //submit button pressed
  onFinish=() =>{
    this.setState({datarequested:true})

    let form_data = new FormData();

    var  alldata={
      'username':this.state.username,
      'email':this.state.email,
      'is_active': this.state.is_active,
      'branch_manager': this.state.branch_manager,
      }

    form_data.append('user_id', this.props.user_id);
    form_data.append('alldata', JSON.stringify(alldata));
    form_data.append('client_id', this.props.client_id);

    //Now send the post resquest
      axios.post(`${serverconfig.backendserverurl}/customqueries/updateinstance_userpassword`, form_data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
      })
    .then(res =>this.setState({datarequested:false}),
      window.location.reload(false)
     )
    .catch(error => console.log(error)) 

    };



  render(){
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin  size="large">
                <div className="content" />
              </Spin> 
        </div>
      )

    }else{

      return (
        <div>
          <Form  
          onFinish={this.onFinish}      
          
          >
  
            <FormItem label="User Name">
              <Input prefix={<UserOutlined className="site-form-item-icon" />} 
              placeholder="Put a name here." 
              value={this.state.username}
              onChange={(val)=>{this.setState({username:val.target.value})}}
              
              />
            </FormItem>
  
            <FormItem label="Email">
              <Input  type="mail" 
              prefix={<MailOutlined className="site-form-item-icon" />} 
               placeholder="Put a name here." 
               value={this.state.email}
               onChange={(val)=>{this.setState({email:val.target.value})}}
               />
            </FormItem>

  
            <FormItem name="active" label="Is active?" >
              <Switch
              checked={this.state.is_active}
              onChange={val=>{this.setState({is_active:val})}}
              checkedChildren="True"
              unCheckedChildren="False"
              />
            </FormItem>
  
            <FormItem name="branch_manager" label="Is managerial account?">
              <Switch
              checked={this.state.branch_manager}
              onChange={val=>{this.setState({branch_manager:val})}}
              checkedChildren="True"
              unCheckedChildren="False"
              />
            </FormItem>
  
            <FormItem>
              <Button htmlType="submit" type="primary">Update Account</Button>
            </FormItem>
          </Form>
  
        </div>
        
      );

    }
   
  }

}

export default InstanceAccountUpdateForm;



