import React from 'react';
import { Form, Input, Button,Select,InputNumber,DatePicker,message,Card } from 'antd';
import axios from 'axios'
import * as serverconfig from '../../serverconn'
import moment from 'moment';
import PhoneInput from 'react-phone-number-input'

import {
  Col,
  Row,
} from 'reactstrap';
import  { Redirect } from 'react-router-dom'

const FormItem=Form.Item;
const { Option } = Select;
const { TextArea } = Input;
const dateFormat = 'DD/MM/YYYY';


class InvoicePaymentForm extends React.Component {

  state = {
    productName:'',  
    unit:'',
    unitprice:'',
    productDescription:'',
    productSerial:'',
    productImage:null,   
    paymentmode:'',
    date:'',
    receiptno:0,
    invoicebalanceafter:0
  }

  componentDidMount(){

    //Now get the last estimate number in db
    let form_data = new FormData();

      axios.post(serverconfig.backendserverurl+'/customqueries/getlastestinvoicepaymentnumber', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res => {
      this.setState({
        receiptno: Number(res.data.lastnumber)+1
      })
    }).catch(error => console.log(error)) 

  }

  

  //submit button pressed
  onFinish=(event) =>{
    const invoiceid=this.props.invoiceid;
    ///invoice balance after
    const newbalance=Number(this.props.dueamount)-Number(event.amount)

    let form_data = new FormData();
    form_data.append('invoiceid', invoiceid);
    form_data.append('date', this.state.date);
    form_data.append('paymentmode', event.paymentmode);
    form_data.append('amount', event.amount);
    form_data.append('comments', event.comments);
    form_data.append('phonenumberfrom', event.phonenumberfrom?event.phonenumberfrom:'');
    form_data.append('phonenumberto', event.phonenumberto?event.phonenumberto:'');
    form_data.append('accountto',event.accountto?event.accountto:"");
    form_data.append('clientbank',event.clientbank?event.clientbank:"");
    form_data.append('transreference', event.transreference?event.transreference:"");
    form_data.append('user', localStorage.getItem('username'));
    form_data.append('chequeno',event.chequeno?event.chequeno:"");
    form_data.append('payemntreceiptno', this.state.receiptno); 
    form_data.append('reasonforpayment', event.reasonofpayment);
    form_data.append('invoicebalance', newbalance);

    ///make a post request now 
      axios.post(serverconfig.backendserverurl+'/customqueries/createinvoicepayment', form_data, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })
    .then(res =>{
      if(res.data.message==="Payment receipt number already exists"){
        message.info(res.data.message)
      }else{
        window.location.reload(false)
      }
    })
    .catch(error => console.log(error))

    }

    handlepaymentmodeChange= (e) => {
       this.setState({paymentmode:e})
    }

    handledatechange= (date, dateString) => this.setState({ date: dateString});
    handlereceiptnoChange= (e) => this.setState({ receiptno: e.target.value});

  render(){
    return (
      <div>
        <Card>
        <Form
              name="productform"
              onFinish={(event) => this.onFinish(event)}
          >

            <FormItem 
                label="Date"
                name="date"
                rules={[
                  {
                    required: true,
                    message: 'Please select date',
                  },
                ]}
            >
              <DatePicker placeholder="date" format={dateFormat} onChange={this.handledatechange} />
            </FormItem>
            <FormItem label="Receipt No">
                <Input placeholder="Receipt NO" value={this.state.receiptno} onChange={this.handlereceiptnoChange}  />
            </FormItem>

            <FormItem 
                  label="Payment Mode"
                  name="paymentmode"
                  rules={[
                    {
                      required: true,
                      message: 'Please select payment mode',
                    },
                  ]}
                  >
                <Select 
                value={this.state.paymentmode} 
                onChange={this.handlepaymentmodeChange}
                placeholder="Payment Mode" >
                      <Option value='cash'>Cash</Option>
                      <Option value='mobilemoney'>Mobile Money</Option>
                      <Option value='cheque'>Cheque</Option>
                      <Option value='electronicfundtransfer'>Electronic Fund Transfer</Option>                         
                </Select>
            </FormItem>

            <FormItem label="Amount"
              name="amount"
              rules={[
                {
                  required: true,
                  message: 'Please input amount',
                },
              ]}>
                <InputNumber
                  style={{ width: 150 }} 
                  defaultValue={0.0}
                  formatter={value => `x ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/\x\s?|(,*)/g, '')}
                />    
                            
              </FormItem>
              
              {
                this.state.paymentmode=="mobilemoney"?

                <div>
                <FormItem 
                label="Phone From"
                name="phonenumberfrom"
                rules={[
                  {
                    required: false,
                    message: 'Please input Phone',
                  },
                ]}
                
                >
                      <PhoneInput
                            countrySelectProps={{ unicodeFlags: true }}
                            defaultCountry="UG"
                            placeholder="Enter phone"
                            />
                </FormItem> 

                <FormItem 
                label="Phone To"
                name="phonenumberto"
                rules={[
                  {
                    required: false,
                    message: 'Please input Phone',
                  },
                ]}
                
                >
                      <PhoneInput
                            countrySelectProps={{ unicodeFlags: true }}
                            defaultCountry="UG"
                            placeholder="Enter phone"
                            />
                </FormItem> 
                </div>
                :
                <br></br>
              }

                {
                this.state.paymentmode=="electronicfundtransfer"               
                ?
                <div>

              <FormItem label="Account To"
                name="accountto"
                rules={[
                  {
                    required: false,
                    message: 'Please input account',
                  },
                ]}
              
              >
                <Input placeholder="Account To"  />
              </FormItem>
          
              <FormItem label="Client Bank"
                name="clientbank"
                rules={[
                  {
                    required: false,
                    message: 'Please input bank',
                  },
                ]}
              
              >
                <Input placeholder="Client Bank"  />
              </FormItem>
          
          
              <FormItem label="Transaction Reference"
                name="transreference"
                rules={[
                  {
                    required: false,
                    message: 'Please input reference',
                  },
                ]}
              
              >
                <Input placeholder="Reference"  />
              </FormItem>

              </div>

                :
                <br></br>

              }


              {
                this.state.paymentmode=="cheque"                
                ?
                <div>

              <FormItem label="Cheque Number"
                name="chequeno"
                rules={[
                  {
                    required: false,
                    message: 'Please input cheque number',
                  },
                ]}
              
              >
                <Input placeholder="Cheque Number"  />
              </FormItem>
        
              </div>

                :
                <br></br>

              }
            <FormItem label="Reason Of Payment "
            name="reasonofpayment"
            rules={[
              {
                required: true,
                message: 'Please input reason',
              },
            ]}
            
            >
                <TextArea
                  placeholder="reason here"
                  autoSize={{ minRows: 2, maxRows: 6 }}
                />
            </FormItem>

            <FormItem label="Comments"
            name="comments"
            rules={[
              {
                required: false,
                message: 'Please input comments',
              },
            ]}
            
            >
                <TextArea
                  placeholder="Comment here"
                  autoSize={{ minRows: 2, maxRows: 6 }}
                />
            </FormItem>

            <FormItem>
              <Button  type="primary" htmlType="submit">Make Payment</Button>
            </FormItem>
            </Form>
        </Card>

      </div>
    );
  }

}


export default InvoicePaymentForm;



